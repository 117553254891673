import React from 'react';
import EventModel from '../../../../api/EventModel';
import switchPath from '../../../../helpers/switchPath';
import { useEventStore } from '../../../../stores';
import styled from 'styled-components';
import Styles from '../../../../helpers/styles';
import EventPicks from './EventPicks';
import EventResults from './EventResults';

interface EventProps {
  eventId: string;
}

const Header = styled.h1`
  color: ${Styles.colors.white};
`;

const Container = styled.div`
  width: 100%;
  padding: 0px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  @media (max-width: ${Styles.smallScreenBreakpoint}) {
    padding: 0px 5px;
  }
`;

const Event: React.FC<EventProps> = ({ eventId }) => {
  const [event, setEvent] = React.useState<EventModel | null>();
  const addEvent = useEventStore((state) => state.addEvent);

  React.useEffect(() => {
    const loadEvent = async (): Promise<any> => {
      try {
        const readEvent = await EventModel.fetch(eventId);
        setEvent(readEvent as EventModel);
        addEvent(readEvent as EventModel);
      } catch (error) {
        switchPath('/');
      }
    };

    loadEvent();
  }, [eventId]);

  const content = React.useMemo(() => {
    if (!event) {
      return null;
    }

    if (event.isUpcomingEvent) {
      return <EventPicks eventId={eventId} />;
    } else {
      return <EventResults eventId={eventId} />;
    }
  }, [event]);

  return (
    <Container>
      <Header>{event?.name ?? ''}</Header>
      {content}
    </Container>
  );
};

export default Event;
