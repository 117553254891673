import Model from './Model';

class DarkHorsePickModel extends Model {
  public eventId: string;
  public leagueMemberId: string;
  public competitorId: string;
  public priority: number;
}

export default DarkHorsePickModel;
