import React from 'react';

import Button from './Button';
import classNames from '../helpers/classNames';

interface PopupProps {
  content: React.ReactNode;
  onClose: () => void;
  onSuccess?: () => void;
  successLabel?: string;
  className?: string;
  disabled?: boolean;
}

const Popup: React.FC<PopupProps> = ({
  content,
  onClose,
  onSuccess,
  successLabel,
  disabled,
  className,
}) => {
  const extraButton = onSuccess ? (
    <Button
      disabled={disabled}
      onClick={onSuccess}
      label={successLabel ?? 'Done'}
    />
  ) : null;
  const popupClasses = classNames('popup', className);
  return (
    <div onClick={disabled ? undefined : onClose} className="popup-overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={popupClasses}
      >
        <div className="popup-content">
          {content}
          <div className="buttons">
            <Button
              disabled={disabled}
              className={onSuccess ? 'close-only' : ''}
              onClick={onClose}
              label="Close"
            />
            {extraButton}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
