import React from 'react';
import styled from 'styled-components';
import Styles from '../helpers/styles';
import Icon, { ICON_TYPES } from './Icon';

export type TabObject = Record<
  string,
  {
    key: string;
    title: string;
    header: string;
    description?: string;
  }
>;

interface PanelProps {
  tabs: TabObject;
  tab: string;
  setTab: (tab: string) => void;
  tabContent: React.ReactNode;
  navigationLabel?: string;
  navigationWidth?: number;
  maxWidth?: number | string;
}

interface SelectableProps {
  selected: boolean;
}

interface HiddenProps {
  mobileHidden: boolean;
}

interface NavigationProps extends HiddenProps {
  width: number;
}

interface ContainerProps {
  maxWidth: string | number;
}

const PanelContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: 512px;
  box-shadow: ${Styles.surfaceBoxShadow};
`;

const NavigationHeader = styled.h2`
  color: var(--color-on-surface);
  font-size: 13px;
  padding-left: 20px;
`;

interface DividerProps {
  mobileHidden: boolean;
}

const Divider = styled.hr<DividerProps>`
  border-color: var(--color-on-surface);
  width: 100%;
  border-bottom-width: 0px;
  box-sizing: border-box;
  border-radius: 0px;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    ${({ mobileHidden }) => (mobileHidden ? 'display: none;' : '')}
  }
`;

const NavigationContainer = styled.div<NavigationProps>`
  background-color: var(--color-surface-two);
  min-width: ${({ width }) => width}px;
  width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    ${({ mobileHidden }) => (mobileHidden ? 'display: none;' : '')}
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  @media (min-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

interface NavigationButtonProps extends SelectableProps {
  firstButton: boolean;
}

const NavigationButton = styled.div<NavigationButtonProps>`
  color: var(--color-white);
  cursor: pointer;
  padding: 20px;

  &:hover {
    background-color: var(--color-on-surface-hover);
  }

  @media (min-width: ${Styles.mobileBreakpoint}) {
    ${({ selected }) =>
      selected ? 'background-color: var(--color-primary) !important;' : ''}
  }

  @media (max-width: ${Styles.mobileBreakpoint}) {
    border-bottom: 1px solid rgba(211, 227, 253, 0.08);

    ${({ firstButton }) =>
      firstButton ? 'border-top: 1px solid rgba(211,227,253,0.08);' : ''}
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div<HiddenProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-surface);
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    ${({ mobileHidden }) => (mobileHidden ? 'display: none;' : '')}
    border-radius: 5px;
  }
`;

const InnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
`;

const ContentHeader = styled.h2`
  color: var(--color-white);
  margin-bottom: 5px;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const ContentDescription = styled.p`
  color: var(--color-on-surface);
`;

const GeneralContent = styled.div`
  width: 100%;
`;

const BackNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;

  color: var(--color-white);
  background-color: var(--color-surface-two);
  border-bottom: 1px solid var(--color-on-surface);
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @media (min-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const TabPanel: React.FC<PanelProps> = ({
  tabs,
  tab,
  setTab,
  tabContent,
  navigationLabel,
  navigationWidth,
  maxWidth: maxWidthProp,
}) => {
  const tabCount = Object.keys(tabs).length;
  const singleTab = tabCount === 1;
  const [showNav, setShowNav] = React.useState(true);

  const maxWidth = maxWidthProp
    ? isNaN(maxWidthProp as number)
      ? maxWidthProp
      : `${maxWidthProp}px`
    : '926px';

  const SettingsNavigation = (
    <NavigationContainer
      width={navigationWidth ?? 256}
      mobileHidden={!showNav || singleTab}
    >
      <NavigationHeader className="py-2">
        {navigationLabel ?? 'Settings'}
      </NavigationHeader>
      {Object.values(tabs).map((tabObj, i) => {
        const selected = tab === tabObj.key;
        const onClick = (): void => {
          setTab(tabObj.key);
          setShowNav(false);
        };

        return (
          <NavigationButton
            selected={selected}
            role="button"
            key={tabObj.key}
            onClick={onClick}
            firstButton={i === 0}
          >
            {tabObj.title}
            <StyledIcon type={ICON_TYPES.CIRCLE_RIGHT} size={32} />
          </NavigationButton>
        );
      })}
    </NavigationContainer>
  );

  const tabTitle = tabs[tab]?.header ?? 'Settings';
  const tabDescription = tabs[tab]?.description;

  const requireDivider = Boolean(tabDescription);

  return (
    <PanelContainer maxWidth={maxWidth}>
      {SettingsNavigation}
      <ContentContainer mobileHidden={showNav && !singleTab}>
        <GeneralContent>
          <BackNavigation>
            {tabCount > 1 && (
              <Icon
                onClick={() => setShowNav(true)}
                type={ICON_TYPES.CIRCLE_LEFT}
                size={24}
              />
            )}
            {tabTitle}
          </BackNavigation>
          <InnerContentContainer>
            <ContentHeader>{tabTitle}</ContentHeader>
            {tabDescription && (
              <ContentDescription>{tabDescription}</ContentDescription>
            )}
          </InnerContentContainer>
          <Divider mobileHidden={!requireDivider} />
          <InnerContentContainer>{tabContent}</InnerContentContainer>
        </GeneralContent>
      </ContentContainer>
    </PanelContainer>
  );
};

export default TabPanel;
