import { type Analytics } from 'firebase/analytics';
import { type Auth } from 'firebase/auth';

const singletons: {
  auth: Auth | null;
  analytics: Analytics | null;
  forceRefreshToken: boolean;
  cloakedUserId?: string | null;
} = {
  auth: null,
  analytics: null,
  forceRefreshToken: false,
  cloakedUserId: null,
};

export default singletons;
