import { create } from 'zustand';
import type CompetitorModel from '../api/CompetitorModel';

interface CompetitorState {
  competitors: Record<string, CompetitorModel>;
  addCompetitor: (competitor: CompetitorModel) => void;
  addCompetitors: (competitorSet: Record<string, CompetitorModel>) => void;
  getCompetitor: (competitorId: string) => CompetitorModel;
  getCompetitors: (competitorIds: string[]) => CompetitorModel[];
}

const useCompetitorStore = create<CompetitorState>((set, get) => ({
  competitors: {},
  addCompetitor: (competitor: CompetitorModel) =>
    set(({ competitors }) => ({
      competitors: { ...competitors, [competitor.id]: competitor },
    })),
  addCompetitors: (competitorSet) =>
    set(({ competitors }) => ({
      competitors: { ...competitors, ...competitorSet },
    })),
  getCompetitor: (competitorId: string) => get().competitors[competitorId],
  getCompetitors: (competitorIds: string[]) => {
    const allCompetitors = get().competitors;
    return competitorIds.map((competitorId) => allCompetitors[competitorId]);
  },
}));

export default useCompetitorStore;
