import React from 'react';
import styled, { css } from 'styled-components';
import Styles from '../../helpers/styles';

const Row = styled.tr``;

interface DisabledProps {
  isDone: boolean;
}

const PlaceholderCellStyles = css<DisabledProps>`
  color: var(--color-white);
  width: 100%;
  text-align: center;
  padding: 10px;
  border: 3px dashed var(--color-white);

  ${({ isDone }) => (isDone ? 'border-color: var(--color-disabled);' : '')}
  ${({ isDone }) => (isDone ? 'color: var(--color-disabled);' : '')}

  height: 50px;
`;

const PlaceholderCell = styled.td<DisabledProps>`
  ${PlaceholderCellStyles}

  @media (max-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const PlaceholderCellMobile = styled.td<DisabledProps>`
  ${PlaceholderCellStyles}

  @media (min-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

interface Props {
  rowNumber: number;
  isDone: boolean;
}

const EmptyGuessRow: React.FC<Props> = ({ rowNumber, isDone }) => {
  return (
    <Row>
      <PlaceholderCell isDone={isDone} colSpan={5}>
        <span>{rowNumber}</span>
      </PlaceholderCell>
      <PlaceholderCellMobile isDone={isDone} colSpan={4}>
        <span>{rowNumber}</span>
      </PlaceholderCellMobile>
    </Row>
  );
};

export default EmptyGuessRow;
