import Model from './Model';

class EventPickModel extends Model {
  public rosterCompetitorId: string;
  public eventId: string;
}

Model.registerModel('EventPicks', EventPickModel);

export default EventPickModel;
