import React from 'react';
import GenericTable from './GenericTable';
import type LeagueModel from '../../../../api/LeagueModel';
import { type LeagueStandings } from '../../../../api/LeagueModel';

interface StandingsProps {
  league: LeagueModel;
}

const Standings: React.FC<StandingsProps> = ({ league }) => {
  const [standings, setStandings] = React.useState<LeagueStandings>([]);
  const [sortIndex, setSortIndex] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState<'desc' | 'asc'>(
    'desc',
  );

  React.useEffect(() => {
    const loadStandings = async (): Promise<any> => {
      const loadedStandings = await league.getStandings();
      setStandings(loadedStandings);
    };

    loadStandings();
  }, [league.id]);

  const headers = ['Place', 'Team', 'Points'];

  const sortedStandings = React.useMemo(() => {
    const standingsCopy = Array.from(standings);
    standingsCopy.sort((a, b) => {
      let sortValue = 0;
      if (sortIndex === 0 || sortIndex === 2) {
        sortValue = b.leaguePoints - a.leaguePoints;
      } else if (sortIndex === 1) {
        sortValue = a.teamName.localeCompare(b.teamName);
      }

      return sortDirection === 'desc' ? sortValue : sortValue * -1;
    });

    return standingsCopy;
  }, [standings, sortIndex, sortDirection]);

  const rows = sortedStandings.map((standing, i) => {
    return {
      key: standing.leagueMemberId,
      cells: [standing.place, standing.teamName, standing.leaguePoints],
    };
  });

  return (
    <GenericTable
      headers={headers}
      rows={rows}
      sortableIndices={[0, 1, 2]}
      sortedIndex={sortIndex}
      sortDirection={sortDirection}
      sort={(i: number) => {
        let newDirection: 'asc' | 'desc' = 'desc';
        if (sortIndex === i && sortDirection === 'desc') {
          newDirection = 'asc';
        }

        setSortIndex(i);
        setSortDirection(newDirection);
      }}
    />
  );
};

export default Standings;
