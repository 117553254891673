import { create } from 'zustand';
import type LeagueEventModel from '../api/LeagueEventModel';

interface LeagueEventState {
  leagueEvents: Record<string, Record<string, LeagueEventModel>>;
  addLeagueEvent: (leagueEvent: LeagueEventModel) => void;
  addLeagueEvents: (
    leagueId: string,
    leagueEventSet: LeagueEventModel[],
  ) => void;
  getLeagueEvent: (leagueId: string, eventId: string) => LeagueEventModel;
  getLeagueEvents: (leagueId: string) => Record<string, LeagueEventModel>;
}

const useLeagueEventStore = create<LeagueEventState>((set, get) => ({
  leagueEvents: {},
  addLeagueEvent: (leagueEvent: LeagueEventModel) =>
    set(({ leagueEvents }) => {
      const newLeague = {
        ...leagueEvents[leagueEvent.leagueId],
        [leagueEvent.eventId]: leagueEvent,
      };
      return {
        leagueEvents: {
          ...leagueEvents,
          [leagueEvent.leagueId]: newLeague,
        },
      };
    }),
  addLeagueEvents: (leagueId: string, leagueEventSet: LeagueEventModel[]) =>
    set(({ leagueEvents }) => {
      const newLeague = {
        ...leagueEvents[leagueId],
      };

      leagueEventSet.forEach((leagueEvent) => {
        newLeague[leagueEvent.eventId] = leagueEvent;
      });
      return {
        leagueEvents: {
          ...leagueEvents,
          [leagueId]: newLeague,
        },
      };
    }),
  getLeagueEvent: (leagueId: string, eventId: string) =>
    get().leagueEvents[leagueId][eventId],
  getLeagueEvents: (leagueId: string) => get().leagueEvents[leagueId] ?? {},
}));

export default useLeagueEventStore;
