import React from 'react';
import classNames from 'classnames';

import Icon, { ICON_TYPES } from './Icon';

interface Props {
  className?: string;
  contentClassName?: string;
  label?: string;
  sortDirection: 'asc' | 'desc';
  onSort: () => void;
  selected?: boolean;
}

const SortableTableHeader: React.FC<Props> = ({
  className,
  contentClassName,
  label,
  sortDirection,
  onSort,
  selected,
}) => {
  const contentClassNames = classNames('content', contentClassName, {
    unsorted: !selected,
    sorted: selected,
    flipped: selected && sortDirection === 'asc',
  });
  const headerClasses = classNames('sortable-table-header', className);
  return (
    <th onClick={onSort} className={headerClasses}>
      <div className={contentClassNames}>
        <span className="label">{label}</span>
        <Icon size={15} type={ICON_TYPES.CIRCLE_DOWN} />
      </div>
    </th>
  );
};

export default SortableTableHeader;
