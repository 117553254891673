import React from 'react';
import Admin, { type AdminUserModel } from '../../../api/Admin';
import GenericTable from '../fantasy/league/GenericTable';

const AdminUsers: React.FC = () => {
  const [users, setUsers] = React.useState<AdminUserModel[]>([]);
  const [userSearch, setUserSearch] = React.useState('');

  React.useEffect(() => {
    const loadUsers = async (): Promise<any> => {
      const userResults = await Admin.getUsers(userSearch);
      setUsers(userResults);
    };

    loadUsers();
  }, [userSearch]);

  const headers = ['Username', 'Admin?'];

  const rows = React.useMemo(() => {
    return users.map((user) => {
      const onClick = (): void => {
        window.localStorage.setItem('cloakedUserId', user.id);
        window.location.href = '/';
      };

      return {
        onClick,
        cells: [user.username, `${user.isAdmin}`],
        key: user.id,
      };
    });
  }, [users]);

  return (
    <div>
      <input
        value={userSearch}
        onChange={(e) => setUserSearch(e.target.value)}
      />
      <GenericTable headers={headers} rows={rows} />
    </div>
  );
};

export default AdminUsers;
