import Request from './Request';

export interface WeeklyPickCompetitor {
  competitorId: string;
  name: string;
  division: string;
  rating: number;
  pdgaNumber: string;
}

export interface WeeklyPickPick {
  id: string;
  competitorId: string;
  userId: string;
  eventId: string;
  division: 'MPO' | 'FPO';
}

export interface WeeklyPickResult {
  username: string;
  points: number;
  userId: string;
}

export interface WeeklyPickInitialData {
  competitors?: WeeklyPickCompetitor[];
  picks?: WeeklyPickPick[];
  results?: WeeklyPickResult[];
}

export interface WeeklyPickUserPick {
  name: string;
  points: number;
  division: 'MPO' | 'FPO';
  competitor_id: string;
}

const WeeklyPick = {
  fetchInitialData: async (eventId: string): Promise<WeeklyPickInitialData> => {
    return Request(`/weekly-picks/${eventId}`);
  },

  getUserPicks: async (
    eventId: string,
    userId: string,
  ): Promise<WeeklyPickUserPick[]> => {
    return Request(`/weekly-picks/${eventId}/user-picks/${userId}`);
  },

  createPick: async (
    eventId: string,
    competitorId: string,
  ): Promise<WeeklyPickPick> => {
    return Request(`/weekly-picks/${eventId}`, {
      method: 'POST',
      body: {
        competitorId,
      },
    });
  },
  deletePick: async (
    eventId: string,
    competitorId: string,
  ): Promise<WeeklyPickPick> => {
    return Request(`/weekly-picks/${eventId}`, {
      method: 'DELETE',
      body: {
        competitorId,
      },
    });
  },
};

export default WeeklyPick;
