import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';

import singletons from './singletons';
import Request from './Request';

interface UserType {
  username: string;
  isAdmin: boolean;
  isBeta: boolean;
  updatedAt: string;
  id: string;
}

interface DefinedError {
  message: string;
  element: string;
}

const verificationURL =
  process.env.ENVIRONMENT === 'production'
    ? 'https://kendisc.com/email-verified'
    : 'http://localhost:3001/email-verified';

type DefinedErrorList = Record<string, DefinedError>;

const User: {
  user: UserType | null;
  signIn: (email: string, password: string) => Promise<any> | undefined;
  signUp: (email: string, password: string) => Promise<any> | undefined;
  signOut: () => Promise<any> | undefined;
  getUser: (forceProps?: { force?: boolean }) => Promise<UserType | null>;
  updateUsername: (username: string) => Promise<string>;
  resetPassword: (email: string) => Promise<any>;
  sendEmailVerification: () => Promise<string | null | undefined> | undefined;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<any>;
  getEmail: () => string | null | undefined;
  reloadUser: () => Promise<boolean>;
  DEFINED_ERRORS: DefinedErrorList;
} = {
  signIn: async (email, password) => {
    if (singletons.auth) {
      return signInWithEmailAndPassword(singletons.auth, email, password);
    }
  },

  signUp: async (email, password) => {
    if (singletons.auth) {
      const result = await createUserWithEmailAndPassword(
        singletons.auth,
        email,
        password,
      );
      return sendEmailVerification(result.user, {
        url: verificationURL,
      });
    }
  },

  signOut: async () => {
    if (singletons.auth) {
      User.user = null;
      return signOut(singletons.auth);
    }
  },

  getUser: async ({ force } = {}) => {
    if (!User.user || force) {
      const user = await Request('/user');
      User.user = {
        username: user.users_username,
        isAdmin: user.users_is_admin,
        isBeta: false,
        updatedAt: user.users_username_last_updated_at,
        id: user.users_id,
      };
    }

    return User.user;
  },

  updateUsername: async (username) => {
    const request = await Request('/user', {
      method: 'PUT',
      body: { username },
    });

    if (User.user) {
      User.user.username = request.username;
    }

    return username;
  },

  resetPassword: async (email) => {
    if (singletons.auth) {
      return sendPasswordResetEmail(singletons.auth, email);
    }
  },

  sendEmailVerification: async () => {
    if (singletons.auth?.currentUser) {
      const email = singletons.auth.currentUser?.email;
      await sendEmailVerification(singletons.auth.currentUser, {
        url: verificationURL,
      });
      return email;
    }
  },

  updatePassword: async (oldPassword, newPassword) => {
    if (!singletons.auth) {
      return;
    }

    const currentUser = singletons.auth.currentUser;

    if (!currentUser?.email) {
      throw new Error('not signed in');
    }

    const credential = EmailAuthProvider.credential(
      currentUser.email,
      oldPassword,
    );
    await reauthenticateWithCredential(currentUser, credential);

    if (singletons.auth.currentUser) {
      return updatePassword(singletons.auth.currentUser, newPassword);
    }
  },

  getEmail: () => {
    return singletons.auth?.currentUser?.email;
  },

  reloadUser: async () => {
    let emailVerified = false;
    if (singletons.auth?.currentUser) {
      await singletons.auth.currentUser.reload();
      emailVerified = Boolean(singletons.auth?.currentUser?.emailVerified);
    }

    return emailVerified;
  },

  DEFINED_ERRORS: {
    'auth/email-already-exists': {
      message: 'There is already an account for this email',
      element: 'email',
    },
    'auth/email-already-in-use': {
      message: 'There is already an account for this email',
      element: 'email',
    },
    'auth/invalid-email': {
      message: 'This is not a valid email',
      element: 'email',
    },
    'auth/weak-password': {
      message: 'Your password must be at least 6 characters long',
      element: 'password',
    },
    'auth/unverified-email': {
      message: 'Check your inbox for a verification email',
      element: 'email',
    },
    'auth/wrong-password': {
      message: 'You entered an incorrect password',
      element: 'password',
    },
    'auth/user-not-found': {
      message: 'Could not find an account with that email',
      element: 'email',
    },
    'auth/too-many-requests': {
      message: 'You are sending requests too fast',
      element: 'alert',
    },
  },

  user: null,
};

export default User;
