import React from 'react';

import type LeagueMemberModel from '../api/LeagueMemberModel';

export default React.createContext<
  [
    Record<string, LeagueMemberModel>,
    (leagues: Record<string, LeagueMemberModel>) => void,
  ]
>([{}, () => {}]);
