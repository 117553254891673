import {
  type FirebaseOptions,
  initializeApp as initializeFirebaseApp,
} from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

import singletons from './singletons';

import FIREBASE_CONFIGS from './config/firebase';
import EventModel from './EventModel';
import CompetitorModel from './CompetitorModel';
import EventCompetitorModel from './EventCompetitorModel';
import RosterCompetitorModel from './RosterCompetitorModel';
import LeagueModel from './LeagueModel';
import Immaculate from './Immaculate';
import EventPickModel from './EventPickModel';
import Model from './Model';
import LeagueMemberModel from './LeagueMemberModel';
import DarkHorsePickModel from './DarkHorsePickModel';
import RosterLogModel from './RosterLogModel';

declare global {
  interface Window {
    EventModel?: any;
    CompetitorModel?: any;
    EventCompetitorModel?: any;
    LeagueModel?: any;
    ImmaculateApi?: any;
    RosterCompetitorModel?: any;
    EventPickModel?: any;
    RosterLogModel?: any;
  }
}

const firebaseConfig: FirebaseOptions =
  FIREBASE_CONFIGS[process.env.ENVIRONMENT ?? 'development'];

const initializeApp = (
  onAuthStateChangedPassed: (stateChange: {
    signedIn: boolean;
    emailVerified: boolean;
  }) => void,
): void => {
  Model.registerModel('LeagueMembers', LeagueMemberModel);
  Model.registerModel('RosterCompetitors', RosterCompetitorModel);
  Model.registerModel('DarkHorsePicks', DarkHorsePickModel);

  const storedCloakedUserId = window.localStorage.getItem('cloakedUserId');
  if (storedCloakedUserId) {
    singletons.cloakedUserId = storedCloakedUserId;
  }

  if (!firebaseConfig) {
    throw new Error('No Firebase Config Available');
  }

  const app = initializeFirebaseApp(firebaseConfig);

  if (process.env.ENVIRONMENT === 'production') {
    // Setup analytics
    singletons.analytics = getAnalytics(app);
  } else {
    // Add model debugging tools
    window.EventModel = EventModel;
    window.CompetitorModel = CompetitorModel;
    window.EventCompetitorModel = EventCompetitorModel;
    window.LeagueModel = LeagueModel;
    window.RosterCompetitorModel = RosterCompetitorModel;
    window.ImmaculateApi = Immaculate;
    window.EventPickModel = EventPickModel;
    window.RosterLogModel = RosterLogModel;
  }
  singletons.auth = getAuth();

  if (singletons.auth) {
    onAuthStateChanged(singletons.auth, (user) => {
      const signedIn = Boolean(user);
      const emailVerified = signedIn && Boolean(user?.emailVerified);
      singletons.forceRefreshToken = true;

      onAuthStateChangedPassed({ signedIn, emailVerified });
    });
  }
};

export default initializeApp;
