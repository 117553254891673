import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Styles from '../helpers/styles';

const Grow = keyframes`
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
`;

const CheckedStyles = css`
  border-color: transparent;
  background: ${Styles.colors.green};
  animation: ${Grow} 0.6s ease;

  &:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
`;

const Container = styled.div``;

const StyledInput = styled.input`
  display: none;
  visibility: hidden;
`;

interface BoxProps {
  checked?: boolean;
}

const Box = styled.label<BoxProps>`
  position: relative;
  display: inline-block;
  top: 1px;
  width: 17px;
  height: 17px;
  border: 1px solid #c8ccd4;
  border-radius: 3px;
  vertical-align: middle;
  transition: background 0.1s ease;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 11px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: all 0.3s ease;
    transition-delay: 0.15s;
  }

  ${({ checked }) => (checked ? CheckedStyles : '')}
`;

interface CheckboxProps {
  checked?: boolean;
  id: string;
}

/**
 *
 * @param param0
 * <div class="checkbox-wrapper-42">
            <input
              id="cbx-42"
              checked={checked}
              type="checkbox"
              onChange={() => {}}
            />
            <label class="cbx" for="cbx-42"></label>
            <label class="lbl" for="cbx-42">Magic</label>
          </div>
 */

const Checkbox: React.FC<CheckboxProps> = ({ id, checked }) => {
  return (
    <Container>
      <StyledInput id={id} checked={checked} onChange={() => {}} />
      <Box checked={checked} />
    </Container>
  );
};

export default Checkbox;
