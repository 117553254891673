import React from 'react';
import TabPanel from '../../components/TabPanel';
import styled from 'styled-components';
import Form from '../../components/Form';
import User from '../../api/User';
import switchPath from '../../helpers/switchPath';
import FormInput from '../../components/FormInput';
import FormSubmit from '../../components/FormSubmit';
import Button from '../../components/Button';
import Alert from '../../helpers/alerts';
import { ICON_TYPES } from '../../components/Icon';
import singletons from '../../api/singletons';

const USERNAME_REGEX = /^[A-Za-z0-9]+$/;

interface ProfileProps {
  signedIn?: boolean;
  emailVerified?: boolean;
}

const TABS = {
  user: {
    key: 'user',
    title: 'User Settings',
    header: 'User Settings',
    description: 'Settings for your profile can be adjusted here.',
  },
  account: {
    key: 'account',
    title: 'Account Settings',
    header: 'Account Settings',
    description: 'Settings for your account can be adjusted here.',
  },
};

const UNVERIFIED_TABS = {
  emailVerification: {
    key: 'emailVerification',
    title: 'Email Verification',
    header: 'Email Verification',
    description:
      'We strongly recommend you verify your email, so that you can do things like reset your password without issue. If you need a verification email, you can request one here.',
  },
  ...TABS,
};

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

interface UserSettingsProps {
  currentUsername: string;
  setCurrentUsername: React.Dispatch<React.SetStateAction<string>>;
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  emailVerified?: boolean;
}

const UserSettings: React.FC<UserSettingsProps> = ({
  currentUsername,
  setCurrentUsername,
  username,
  setUsername,
  emailVerified,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState<string | null>('');

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();

    if (username !== currentUsername) {
      if (username.length === 0) {
        setError('You must set a username');
      } else if (username.length > 30) {
        setError('Your username cannot be more than 30 characters');
      } else if (!USERNAME_REGEX.test(username)) {
        setError('You may only use letters or numbers in your username');
      } else {
        setIsSaving(true);
        try {
          const newUsername = await User.updateUsername(username);
          setCurrentUsername(newUsername);
        } catch (e) {
          setError((e as Error).toString());
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  const canSaveUsername = Boolean(
    !isSaving && !error && emailVerified && username !== currentUsername,
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        inputKey="username"
        label="Username"
        onChange={(e) => {
          const newUsername = e.target.value;
          setError(null);
          if (newUsername.length === 0) {
            setError('You must set a username');
          } else if (newUsername.length > 30) {
            setError('Your username cannot be more than 30 characters');
          } else if (!USERNAME_REGEX.test(newUsername)) {
            setError('You may only use letters or numbers in your username');
          } else {
            setError(null);
          }
          setUsername(newUsername);
        }}
        required
        value={username}
        disabled={!emailVerified || Boolean(isSaving || error)}
      />
      <FormSubmit disabled={!canSaveUsername} />
    </Form>
  );
};

const AccountSettings: React.FC = () => {
  const [password, setPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState<string | null>('');

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();

    if (password !== passwordConfirm) {
      setError('Your passwords do not match.');
      return;
    }

    try {
      setIsSaving(true);
      await User.updatePassword(oldPassword, password);
      setPassword('');
      setPasswordConfirm('');
    } catch (e) {
      const definedError = e.code ? User.DEFINED_ERRORS[e.code] : null;
      if (e.code === 'auth/wrong-password') {
        setError('You did not enter the correct current password');
      } else if (definedError?.message) {
        setError(definedError.message);
      } else {
        // Alert.addAlert((e as Error).message);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const canUpdatePassword = Boolean(
    !isSaving &&
      !error &&
      password &&
      oldPassword &&
      password === passwordConfirm,
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        inputKey="email"
        label="Email"
        value={singletons?.auth?.currentUser?.email ?? ''}
        required
        type="email"
        autoComplete="username"
        disabled
        hidden
      />
      <FormInput
        inputKey="new-password"
        label="New Password"
        type="password"
        autoComplete="new-password"
        onChange={(e) => {
          setError(null);
          setPassword(e.target.value);
        }}
        required
        value={password}
        disabled={Boolean(isSaving)}
      />
      <FormInput
        inputKey="confirm-password"
        label="Confirm New Password"
        type="password"
        autoComplete="new-password"
        onChange={(e) => {
          setError(null);
          setPasswordConfirm(e.target.value);
        }}
        required
        value={passwordConfirm}
        disabled={Boolean(isSaving)}
      />
      <FormInput
        inputKey="old-password"
        label="Old Password"
        type="password"
        autoComplete="current-password"
        onChange={(e) => {
          setError(null);
          setOldPassword(e.target.value);
        }}
        required
        value={oldPassword}
        disabled={Boolean(isSaving)}
      />
      <FormSubmit disabled={!canUpdatePassword} label="Update Password" />
    </Form>
  );
};

const EmailVerificationSettings: React.FC = () => {
  const [isSending, setIsSending] = React.useState(false);
  const resendVerificationEmail = async (): Promise<any> => {
    try {
      setIsSending(true);
      await User.sendEmailVerification();
      Alert.addAlert('Resent Verification Email', {
        icon: ICON_TYPES.CIRCLE_CHECK,
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Button
      disabled={isSending}
      className="resend-button"
      onClick={resendVerificationEmail}
      label="Resend Verification Email"
    />
  );
};

const Profile: React.FC<ProfileProps> = ({ signedIn, emailVerified }) => {
  const [tab, setTab] = React.useState(
    emailVerified ? 'user' : 'emailVerification',
  );
  const [currentUsername, setCurrentUsername] = React.useState('');
  const [username, setUsername] = React.useState('');

  React.useEffect(() => {
    const loadUser = async (): Promise<any> => {
      if (!currentUsername) {
        const fetchedUser = await User.getUser();
        setUsername(fetchedUser?.username ?? '');
        setCurrentUsername(fetchedUser?.username ?? '');
      }
    };

    if (signedIn) {
      loadUser();
    } else {
      switchPath('/');
    }
  }, [signedIn]);

  const tabContent = React.useMemo(() => {
    if (tab === 'user') {
      return (
        <UserSettings
          currentUsername={currentUsername}
          setCurrentUsername={setCurrentUsername}
          username={username}
          setUsername={setUsername}
          emailVerified={emailVerified}
        />
      );
    } else if (tab === 'account') {
      return <AccountSettings />;
    } else if (tab === 'emailVerification') {
      return <EmailVerificationSettings />;
    }

    return null;
  }, [tab, username, currentUsername, emailVerified]);

  return (
    <ProfileContainer>
      <TabPanel
        tabs={emailVerified ? TABS : UNVERIFIED_TABS}
        tab={tab}
        setTab={setTab}
        tabContent={tabContent}
      />
    </ProfileContainer>
  );
};

export default Profile;
