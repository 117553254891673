import React from 'react';

import Logo from '../../../components/logo';
import classNames from '../../../helpers/classNames';
import type PickModel from '../../../api/PickModel';
import type EventModel from '../../../api/EventModel';
import { type LeaderboardType } from '../../../api/EventModel';

const DIVISIONS = [
  {
    label: 'Overall',
    key: 'all',
  },
  {
    label: 'MPO',
    key: 'mpo',
  },
  {
    label: 'FPO',
    key: 'fpo',
  },
];

interface ExpandedSectionProps {
  isLoading?: boolean;
  picks: PickModel[];
  division: string;
}

const ExpandedSection: React.FC<ExpandedSectionProps> = ({
  isLoading,
  picks,
  division,
}) => {
  if (isLoading) {
    return <Logo size={20} color="emphasis" spinning />;
  }

  const eventCompetitors = React.useMemo(() => {
    return picks.map((pick) => pick.eventCompetitor);
  }, [picks]);

  const all = division === 'all';
  const showMpo = all || division === 'mpo';
  const showFpo = all || division === 'fpo';

  const getCompetitorString = (
    eventCompetitor,
    includeComma = true,
  ): React.ReactNode => (
    <React.Fragment key={eventCompetitor.id}>
      {includeComma && ', '}
      {`${eventCompetitor.competitor.name} `}
      <b>{`(${eventCompetitor.points || 0})`}</b>
    </React.Fragment>
  );

  const getPickString = (pickDivision): React.ReactNode => {
    const competitorsCopy = eventCompetitors
      ? eventCompetitors.filter(
          (eventCompetitor) => eventCompetitor?.division === pickDivision,
        )
      : [];

    if (competitorsCopy.length === 0) {
      return 'No Picks Avaiable';
    }

    competitorsCopy.sort((a, b) => {
      return (b?.points ?? 0) - (a?.points ?? 0);
    });

    const competitorStrings = [getCompetitorString(competitorsCopy[0], false)];
    for (let i = 1; i < competitorsCopy.length; i++) {
      competitorStrings.push(getCompetitorString(competitorsCopy[i]));
    }

    return <>{competitorStrings}</>;
  };

  const fpoRow = showFpo ? (
    <div>
      <span>
        <b>{'FPO: '}</b>
        {getPickString('fpo')}
      </span>
    </div>
  ) : null;

  const mpoRow = showMpo ? (
    <div>
      <span>
        <b>{'MPO: '}</b>
        {getPickString('mpo')}
      </span>
    </div>
  ) : null;

  return (
    <div className="expanded-section">
      {mpoRow}
      {fpoRow}
    </div>
  );
};

interface LeaderboardProps {
  event: EventModel;
}

const Leaderboard: React.FC<LeaderboardProps> = ({ event }) => {
  const [leaderboard, setLeaderboard] = React.useState<LeaderboardType | null>(
    null,
  );
  const [division, setDivision] = React.useState(DIVISIONS[0].key);
  const [expandedUserIds, setExpandedUserIds] = React.useState<string[]>([]);
  const [picks, setPicks] = React.useState({});

  React.useEffect(() => {
    const loadLeaderboard = async (): Promise<any> => {
      if (!leaderboard) {
        const loadedLeaderboard = await event.getLeaderboard();
        setLeaderboard(loadedLeaderboard);
      }
    };
    loadLeaderboard();
  }, [event.id]);

  const pointsKey = React.useMemo(() => {
    return division === 'all'
      ? 'points'
      : division === 'fpo'
        ? 'fpoPoints'
        : 'mpoPoints';
  }, [division]);

  const sortedLeaderboard = React.useMemo(() => {
    const rows = Array.from(leaderboard ?? []);
    rows.sort((a, b) => b[pointsKey] - a[pointsKey]);

    return rows;
  }, [leaderboard, pointsKey]);

  const leaderboardRows = React.useMemo(() => {
    const rows = Array.from(sortedLeaderboard || []);
    rows.sort((a, b) => b[pointsKey] - a[pointsKey]);

    return rows.map((row, index) => {
      const odd = index % 2 === 1;
      const isExpanded = expandedUserIds.includes(row.userId);
      const isRowLoading = isExpanded && !picks[row.userId];
      const expandedRow = isExpanded ? (
        <tr
          className={classNames('expanded-row', { odd })}
          key={`${row.userId}-expanded`}
        >
          <td colSpan={3}>
            <ExpandedSection
              isLoading={isRowLoading}
              picks={picks[row.userId]}
              division={division}
            />
          </td>
        </tr>
      ) : null;

      const rowClasses = classNames('leaderboard-row', { odd });

      return (
        <React.Fragment key={row.userId}>
          <tr
            key={row.userId}
            className={rowClasses}
            onClick={() => {
              if (isExpanded) {
                const arrCopy = Array.from(expandedUserIds);
                arrCopy.splice(arrCopy.indexOf(row.userId), 1);
                setExpandedUserIds(arrCopy);
              } else {
                // loadPicks(row.userId);
                if (!picks[row.userId]) {
                  event
                    .getUserPicks({ userId: row.userId })
                    .then((newPicks) => {
                      setPicks({
                        ...picks,
                        [row.userId]: newPicks,
                      });
                    });
                }
                setExpandedUserIds([...expandedUserIds, row.userId]);
              }
            }}
          >
            <td>{`${index + 1}`}</td>
            <td>{row.username}</td>
            <td>{row[pointsKey]}</td>
          </tr>
          {expandedRow}
        </React.Fragment>
      );
    });
  }, [
    JSON.stringify(sortedLeaderboard),
    pointsKey,
    JSON.stringify(expandedUserIds),
    JSON.stringify(picks),
  ]);

  const spinner = leaderboard ? null : (
    <Logo size={30} color="emphasis" spinning />
  );

  const divisionSelections = DIVISIONS.map((divisionObj) => {
    const selected = division === divisionObj.key;

    return (
      <div
        key={divisionObj.key}
        className="division-group"
        onClick={() => setDivision(divisionObj.key)}
      >
        <label htmlFor={divisionObj.key}>{divisionObj.label}</label>
        <input
          className="division-radio-button"
          type="radio"
          name="division-selector"
          value={divisionObj.key}
          checked={selected}
          onChange={(e) => setDivision(e.target.value)}
        />
      </div>
    );
  });

  return (
    <div className="leaderboard">
      <h2 className="leaderboard-header">Leaderboard</h2>
      {leaderboard && (
        <div className="division-selection">{divisionSelections}</div>
      )}
      {spinner}
      {leaderboard && (
        <div className="leaderboard-table-container">
          <table className="leaderboard-table">
            <thead>
              <tr className="header-row">
                <th>Place</th>
                <th>Username</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>{leaderboardRows}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
