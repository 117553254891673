import React from 'react';
import type LeagueModel from '../../../../../api/LeagueModel';
import styled from 'styled-components';
import Checkbox from '../../../../../components/Checkbox';
import { useLeagueEventStore } from '../../../../../stores';
import type LeagueEventModel from '../../../../../api/LeagueEventModel';

interface EventSettingsProps {
  league: LeagueModel;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

const TableHeader = styled.th`
  color: var(--color-on-primary);
  text-align: left;
`;

const TableCell = styled.td`
  color: var(--color-on-primary);
`;

interface MultiplierFormProps {
  leagueEvent: LeagueEventModel;
  league: LeagueModel;
}

const MultiplierForm: React.FC<MultiplierFormProps> = ({
  leagueEvent,
  league,
}) => {
  const addLeagueEvent = useLeagueEventStore((state) => state.addLeagueEvent);

  const [inputValue, setInputValue] = React.useState<string>(
    String(leagueEvent.multiplier),
  );
  if (!league.isOwner) {
    return <span>{leagueEvent.multiplier}</span>;
  }

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();

    await leagueEvent.updateMultiplier(Number(inputValue));
    addLeagueEvent(leagueEvent);
  };

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<any> => {
    setInputValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="number"
        value={inputValue}
        step={0.25}
        min={0}
        max={25}
        inputMode="numeric"
        onChange={handleChange}
      ></input>
    </form>
  );
};

const EventSettings: React.FC<EventSettingsProps> = ({ league }) => {
  const leagueEvents = useLeagueEventStore((state) =>
    state.getLeagueEvents(league.id),
  );
  const addLeagueEvents = useLeagueEventStore((state) => state.addLeagueEvents);
  const addLeagueEvent = useLeagueEventStore((state) => state.addLeagueEvent);

  React.useEffect(() => {
    const loadLeagueEvents = async (): Promise<any> => {
      const readEvents = await league.getLeagueEvents();

      addLeagueEvents(league.id, readEvents);
    };

    loadLeagueEvents();
  }, [league.id]);

  const eventRows = React.useMemo(() => {
    return Object.values(leagueEvents).map((leagueEvent) => {
      const flipEnabled = async (): Promise<any> => {
        await leagueEvent.flipEnabled();
        addLeagueEvent(leagueEvent);
      };

      return (
        <tr key={leagueEvent.eventId}>
          <TableCell onClick={league.isOwner ? flipEnabled : undefined}>
            <Checkbox
              id={`${leagueEvent.eventId}-enabled`}
              checked={leagueEvent.enabled}
            />
          </TableCell>
          <TableCell>{leagueEvent.name}</TableCell>
          <TableCell>{leagueEvent.formattedStartDate}</TableCell>
          <TableCell>
            <MultiplierForm leagueEvent={leagueEvent} league={league} />
          </TableCell>
        </tr>
      );
    });
  }, [leagueEvents]);

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <TableHeader></TableHeader>
            <TableHeader>{'Name'}</TableHeader>
            <TableHeader>{'Start Date'}</TableHeader>
            <TableHeader>{'Multiplier'}</TableHeader>
          </tr>
        </thead>
        <tbody>{eventRows}</tbody>
      </table>
    </Container>
  );

  // return leagueEvents.map((leagueEvent) => (<div key={leagueEvent.eventId}>{leagueEvent.multiplier}</div>));
};

export default EventSettings;
