import React from 'react';
import Styles from '../../helpers/styles';
import styled, { css } from 'styled-components';
import LeagueModel from '../../api/LeagueModel';
import switchPath from '../../helpers/switchPath';
import Link from '../../components/Link';
import Alert from '../../helpers/alerts';
import { useLeagueStore } from '../../stores';

const Header = styled.h1`
  color: ${Styles.colors.white};
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameContainer = styled.div`
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${Styles.colors.border};
  padding: 5px;
  border-radius: 3px;
  background-color: ${Styles.colors.page};

  &:focus-within {
    border-color: ${Styles.colors.emphasis};
  }
  margin-bottom: 15px;
`;

const NameLabel = styled.label`
  color: ${Styles.colors.font};
  font-size: 12px;
  width: 100%;
  padding-bottom: 8px;
`;

const NameInput = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${Styles.colors.font};
  outline: none;
  font-size: 18px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid ${Styles.colors.border};
`;

const StyledLink = styled(Link)`
  margin-top: 10px;
`;

interface SubmitProps {
  disabled: boolean;
}

const EnabledStyles = css`
  background-color: ${Styles.colors.emphasis};
  cursor: pointer;
  &:hover {
    background-color: #1d2a70;
  }
`;

const Submit = styled.input<SubmitProps>`
  background-color: ${Styles.colors.page};
  font-size: 24px;
  padding: 10px 50px;
  color: ${Styles.colors.white};
  border-radius: 9999px;
  border: none;
  width: 90%;
  max-width: 500px;
  ${({ disabled }) => (disabled ? '' : EnabledStyles)}
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeagueCreate: React.FC = () => {
  const [leagueName, setLeagueName] = React.useState('');
  const [leaguePassword, setLeaguePassword] = React.useState('');
  const addLeague = useLeagueStore((state) => state.addLeague);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();

    if (!canSubmit) {
      return;
    }

    try {
      const newLeague = await LeagueModel.create({
        name: leagueName,
        password: leaguePassword,
      });
      if (newLeague?.id) {
        addLeague(newLeague);
        switchPath(`/fantasy/${newLeague.id}`);
      } else {
        switchPath('/fantasy');
      }
    } catch (error) {
      Alert.addAlert('Currently, only one league allowed per user');
    }
  };

  const canSubmit = React.useMemo(() => {
    return Boolean(leagueName.length) && Boolean(leaguePassword.length);
  }, [leagueName, leaguePassword]);

  return (
    <Page>
      <Header>Create a League</Header>
      <Form onSubmit={handleSubmit}>
        <NameContainer>
          <NameLabel htmlFor="league-name">{'League Name'}</NameLabel>
          <NameInput
            id="league-name"
            value={leagueName}
            onChange={(e) => {
              if (e.target.value.length > 256) {
                return;
              }
              setLeagueName(e.target.value);
            }}
            required
          />
        </NameContainer>
        <NameContainer>
          <NameLabel htmlFor="league-password">{'League Password'}</NameLabel>
          <NameInput
            id="league-password"
            value={leaguePassword}
            onChange={(e) => {
              if (e.target.value.length > 256) {
                return;
              }
              setLeaguePassword(e.target.value);
            }}
            required
          />
        </NameContainer>
        <StyledLink href="/fantasy/leagues/join">
          {'Join an Existing League'}
        </StyledLink>
        <SubmitContainer>
          <Submit type="submit" value={'Create League'} disabled={!canSubmit} />
        </SubmitContainer>
      </Form>
    </Page>
  );
};

export default LeagueCreate;
