import React from 'react';

import classNames from '../../../helpers/classNames';

import PageFooter from '../../../components/PageFooter';
import FantasySidebar from './FantasySidebar';
import ProfilePage from '../Profile';
import EventPage from '../Event';
import FantasyHeader from './FantasyHeader';
import Leagues from './Leagues';
import LeagueCreate from '../LeagueCreate';
import LeagueJoin from '../LeagueJoin';
import League from './League';
import LeagueSearch from '../LeagueSearch';
import switchPath from '../../../helpers/switchPath';
import WeeklyPicks from './WeeklyPicks';
import Event from './weekly-picks/Event';
import styled from 'styled-components';
import { useLeagueStore } from '../../../stores';
import LeagueModel from '../../../api/LeagueModel';
import NotFound from '../../NotFound';
import Admin from '../admin/Admin';
import Discle from '../../Discle';
import Authentication from '../../Authentication';

interface FantasyProps {
  isAdmin?: boolean | null;
  signedIn?: boolean;
  emailVerified?: boolean;
  path: string;
}

const FantasyContentContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const PUBLIC_ROUTES = [
  'sign-in',
  'sign-up',
  'password-reset',
  'discle',
  'mcbirdle',
];

const Fantasy: React.FC<FantasyProps> = ({
  isAdmin,
  signedIn,
  emailVerified,
  path,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [league, setLeague] = React.useState<LeagueModel | null>(null);
  const addLeague = useLeagueStore((state) => state.addLeague);

  const fantasyClasses = classNames('fantasy');

  React.useEffect(() => {
    setExpanded(false);
  }, [path]);

  React.useEffect(() => {
    const splitPath = path.split('/');
    if (!signedIn && !PUBLIC_ROUTES.includes(splitPath[1])) {
      switchPath('/sign-in');
    }

    if (splitPath[1] === 'discle') {
      switchPath('/mcbirdle');
    }
  }, [path, signedIn]);

  React.useEffect(() => {
    const loadLeague = async (leagueId: string): Promise<any> => {
      try {
        const readLeague = (await LeagueModel.read(leagueId)) as LeagueModel;
        setLeague(readLeague);
        addLeague(readLeague);
      } catch (error) {
        switchPath('/');
      }
    };

    const splitPath = path.split('/');
    if (
      splitPath[1] === 'fantasy' &&
      splitPath[2] === 'leagues' &&
      splitPath[3] !== 'join' &&
      splitPath[3] !== 'create'
    ) {
      const leagueId = splitPath[3];
      if (league?.id !== leagueId) {
        loadLeague(leagueId);
      }
    }
  }, [path]);

  const title = React.useMemo(() => {
    const splitPath = path.split('/');

    switch (splitPath[1]) {
      case 'password-reset':
        return 'Reset Password';
      case 'sign-in':
        return 'Sign In';
      case 'sign-up':
        return 'Sign Up';
      case 'admin':
        return 'Admin';
      case 'discle':
        return 'Discle';
      case 'mcbirdle':
        return 'McBirdle';
      case 'profile':
        return 'Settings';
      case 'par-three-picks':
        return "Par 3 Pick 'em";
      case 'fantasy':
        if (splitPath.length >= 4) {
          const leagueId = splitPath[3];
          if (leagueId === 'join') {
            return 'Join League';
          } else if (leagueId === 'create') {
            return 'Create League';
          } else {
            if (league?.name) {
              return league.name;
            } else {
              return '';
            }
          }
        }
        return 'Leagues';
    }

    return '';
  }, [path, league]);

  const content = React.useMemo(() => {
    const splitPath = path.split('/');
    if (!signedIn && !PUBLIC_ROUTES.includes(splitPath[1])) {
      return null;
    }

    switch (splitPath[1]) {
      case 'password-reset':
        return <Authentication signedIn={signedIn} resetPassword />;
      case 'sign-up':
        return <Authentication signedIn={signedIn} signUp />;
      case 'sign-in':
        return <Authentication signedIn={signedIn} />;
      case 'discle':
      case 'mcbirdle':
        return <Discle />;
      case 'admin':
        if (signedIn && emailVerified && isAdmin) {
          return <Admin />;
        } else {
          return <NotFound />;
        }
      case 'profile':
        return (
          <ProfilePage signedIn={signedIn} emailVerified={emailVerified} />
        );
      case 'par-three-picks':
        if (splitPath.length === 4 && splitPath[2] === 'events') {
          return <Event eventId={splitPath[3]} />;
        }
        return <WeeklyPicks />;
      case 'fantasy':
        if (splitPath.length > 2) {
          switch (splitPath[2]) {
            case 'events':
              return <EventPage signedIn={signedIn} />;
            case 'leagues':
              if (splitPath.length > 3) {
                switch (splitPath[3]) {
                  case 'join':
                    if (splitPath.length > 4) {
                      return <LeagueJoin leagueId={splitPath[4]} />;
                    } else {
                      return <LeagueSearch />;
                    }
                  case 'create':
                    return <LeagueCreate />;
                  default: {
                    const extraPath = splitPath.slice(5);
                    if (splitPath.length === 4) {
                      return (
                        <League
                          leagueId={splitPath[3]}
                          tab="events"
                          extraPath={extraPath}
                        />
                      );
                    } else {
                      switch (splitPath[4]) {
                        case 'standings':
                          return (
                            <League
                              leagueId={splitPath[3]}
                              tab="standings"
                              extraPath={extraPath}
                            />
                          );
                        case 'teams':
                          return (
                            <League
                              leagueId={splitPath[3]}
                              tab="teams"
                              extraPath={extraPath}
                            />
                          );
                        case 'free-agents':
                          return (
                            <League
                              leagueId={splitPath[3]}
                              tab="free-agents"
                              extraPath={extraPath}
                            />
                          );
                        case 'events':
                          return (
                            <League
                              leagueId={splitPath[3]}
                              tab="events"
                              extraPath={extraPath}
                            />
                          );
                        case 'settings':
                          return (
                            <League
                              leagueId={splitPath[3]}
                              tab="settings"
                              extraPath={extraPath}
                            />
                          );
                      }
                    }
                  }
                }
              } else {
                return <Leagues signedIn={signedIn} />;
              }
          }
        }
        return <Leagues signedIn={signedIn} />;
      default:
        return <NotFound />;
    }
  }, [path, signedIn]);

  const hidePageFooter = React.useMemo(() => {
    return ['/fantasy/leagues/create', '/fantasy/leagues/join'].some((p) =>
      path.includes(p),
    );
  }, [path]);

  return (
    <div className={fantasyClasses}>
      <FantasySidebar
        setExpanded={() => {
          setExpanded(!expanded);
        }}
        expanded={expanded}
        signedIn={signedIn}
        path={path}
      />
      <div className="fantasy-content">
        <FantasyHeader
          signedIn={signedIn}
          expanded={expanded}
          title={title}
          setExpanded={() => {
            setExpanded(!expanded);
          }}
        />
        <FantasyContentContainer>{content}</FantasyContentContainer>
        {!hidePageFooter && (
          <PageFooter isAdmin={isAdmin} signedIn={signedIn} />
        )}
      </div>
    </div>
  );
};

export default Fantasy;
