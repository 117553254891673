import React from 'react';

interface PaginatedTableProps {
  headers: React.ReactNode[];
  rows: React.ReactNode[][];
  pageNumber: number;
  pageSize: number;
  totalRows: number;
  changePage: (pageNumber: number) => void;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  headers,
  rows,
  pageNumber,
  pageSize,
  totalRows,
  changePage,
}) => {
  return (
    <div className="w-full">
      <div className="bg-sky-900 rounded-t-md h-[5px]"></div>
      <table className="table-auto w-full">
        <thead>
          {headers.map((header, idx) => (
            <th
              className="bg-sky-900 text-gray-100 text-left p-3 border-b border-sky-700"
              key={idx}
            >
              {header}
            </th>
          ))}
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <tr key={idx}>
              {row.map((cell, idx) => (
                <td
                  className="bg-sky-950 text-gray-100 p-3 border-b border-sky-600"
                  key={idx}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bg-sky-900 p-3 rounded-b-md flex flex-row justify-between w-full">
        <p className="text-gray-100">
          <span className="hidden md:inline">{'Showing '}</span>
          <span className="font-semibold">
            {(pageNumber - 1) * pageSize + 1}
          </span>
          <span>{' - '}</span>
          <span className="font-semibold">
            {Math.min(pageNumber * pageSize, totalRows)}
          </span>
          <span>{' of '}</span>
          <span className="font-semibold">{totalRows}</span>
          <span className="hidden md:inline">{' results'}</span>
        </p>
        <div className="grid grid-cols-2 gap-x-2">
          <button
            className="border border-sky-600 py-1 px-2 rounded-md bg-sky-700 hover:bg-sky-600 text-gray-100 disabled:text-gray-300 disabled:bg-sky-900 disabled:border-sky-800"
            disabled={pageNumber <= 1}
            onClick={() => changePage(pageNumber - 1)}
          >
            {'Previous'}
          </button>
          <button
            className="border border-sky-600 py-1 px-2 rounded-md bg-sky-700 hover:bg-sky-600 text-gray-100 disabled:text-gray-300 disabled:bg-sky-800"
            disabled={pageNumber * pageSize >= totalRows}
            onClick={() => changePage(pageNumber + 1)}
          >
            {'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginatedTable;
