type Value = string | boolean | undefined | null;
type Mapping = Record<string, any>;
type Argument = Value | Mapping;

const classNames = (...args: Argument[]): string => {
  if (args.length === 0) {
    return '';
  }

  const finalArg = args.splice(args.length - 1, 1)[0];

  if (finalArg && typeof finalArg === 'object') {
    const extraArgs: string[] = [];

    Object.keys(finalArg).forEach((key) => {
      if (finalArg[key]) {
        extraArgs.push(key);
      }
    });

    return [...args, ...extraArgs].join(' ');
  } else {
    return [...args, finalArg].join(' ');
  }
};

export default classNames;
