import classNames from '../helpers/classNames';
import switchPath from '../helpers/switchPath';

import React from 'react';

interface Props {
  external?: boolean;
  href: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Link: React.FC<Props> = (props) => {
  const onClick = props.external
    ? undefined
    : (event) => {
        // Means opening in new tab
        if (event.metaKey || event.ctrlKey) {
          return;
        }

        // Prevent reload
        event.preventDefault();

        switchPath(props.href);
      };

  const classes = classNames('link', props.className);

  return (
    <a
      className={classes}
      href={props.href}
      onClick={props.onClick ? props.onClick : onClick}
      target={props.external ? '_blank' : undefined}
      rel={props.external ? 'noreferrer' : undefined}
    >
      {props.children}
    </a>
  );
};

export default Link;
