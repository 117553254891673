import React from 'react';
import type LeagueModel from '../../../api/LeagueModel';
import PaginatedTable from '../../../components/PaginatedTable';
import type CompetitorModel from '../../../api/CompetitorModel';

interface FreeAgentProps {
  league: LeagueModel;
}

const FreeAgents: React.FC<FreeAgentProps> = ({ league }) => {
  const [freeAgents, setFreeAgents] = React.useState<CompetitorModel[]>([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [totalFreeAgents, setTotalFreeAgents] = React.useState(0);
  React.useEffect(() => {
    const fetchLogs = async (): Promise<any> => {
      const [totalRows, freeAgents] = await league.fetchFreeAgents({
        pageNumber,
      });
      setFreeAgents(Object.values(freeAgents));
      setTotalFreeAgents(totalRows);
    };

    fetchLogs();
  }, [pageNumber]);

  return (
    <div className="w-full">
      <h1 className="text-xl font-bold text-gray-50 mb-4">Free Agents</h1>
      <PaginatedTable
        headers={['Player', 'Rating', 'PDGA Number']}
        rows={freeAgents.map((freeAgent) => [
          freeAgent.name,
          freeAgent.rating,
          freeAgent.pdgaNumber,
        ])}
        pageNumber={pageNumber}
        pageSize={25}
        totalRows={totalFreeAgents}
        changePage={setPageNumber}
      />
    </div>
  );
};

export default FreeAgents;
