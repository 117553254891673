import React from 'react';
import EventModel from '../../../../api/EventModel';
import styled from 'styled-components';
import Styles from '../../../../helpers/styles';
import type LeagueModel from '../../../../api/LeagueModel';
import Link from '../../../../components/Link';
import Icon, { ICON_COLORS, ICON_TYPES } from '../../../../components/Icon';
import YourPicks from './EventTabs/YourPicks';
import Results from './EventTabs/Results';
import DarkHorse from './EventTabs/DarkHorse';
import { useEventStore } from '../../../../stores';
import switchPath from '../../../../helpers/switchPath';
import TabPanel, { type TabObject } from '../../../../components/TabPanel';

const Container = styled.div`
  width: 100%;
`;
const BackLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 7px;
  cursor: pointer;
  margin-bottom: 5px;

  > .icon {
    transform: rotate(180deg);
  }
`;

const EventCard = styled.div`
  padding: 10px;

  border-radius: 4px;
  background-color: var(--color-surface-two);
  box-shadow: ${Styles.surfaceBoxShadow};

  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Divider = styled.hr`
  border: 0;
  clear: both;
  display: block;
  width: 80%;
  background-color: ${Styles.colors.green};
  height: 3px;
  margin: 15px 0px;
`;

const NameHeader = styled.h2`
  color: ${Styles.colors.font};
  margin-top: 10px;
  margin-bottom: 0px;
`;

const PresentedByHeader = styled.h3`
  color: ${Styles.colors.font};
  font-weight: 300;
  margin-top: 5px;
  font-size: 1em;
  margin-bottom: 0px;
`;

const DateHeader = styled.h3`
  color: ${Styles.colors.font};
  margin-top: 5px;
  margin-bottom: 5px;
`;

const LocationHeader = styled.h4`
  color: ${Styles.colors.font};
  margin-top: 3px;
  margin-bottom: 3px;
`;

const UpdatedHeader = styled.h4`
  color: ${Styles.colors.font};
  margin-top: 10px;
  margin-bottom: 3px;
  font-weight: 300;
`;

interface EventProps {
  eventId: string;
  league: LeagueModel;
}

const FUTURE_TABS: TabObject = {
  picks: {
    key: 'picks',
    title: 'Your Picks',
    header: 'Your Picks',
  },
  darkHorse: {
    key: 'darkHorse',
    title: 'Dark Horse',
    header: 'Dark Horse Pick',
    description: 'Select your dark horse for the event.',
  },
};

const CURRENT_OR_PAST_TABS: TabObject = {
  results: {
    key: 'results',
    title: 'Results',
    header: 'Results',
    description: 'Results for the event.',
  },
};

const Event: React.FC<EventProps> = ({ eventId, league }) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [event, setEvent] = React.useState<EventModel | null>();

  const params = new URLSearchParams(window.location.search);
  const tabParam = params.get('tab');
  const [tab, setTabNew] = React.useState('picks');

  const addEvent = useEventStore((state) => state.addEvent);

  const TAB_LIST = React.useMemo(() => {
    if (!event) {
      return {};
    }

    return event.isUpcomingEvent ? FUTURE_TABS : CURRENT_OR_PAST_TABS;
  }, [event?.isUpcomingEvent]);

  React.useEffect(() => {
    if (Object.keys(TAB_LIST).length === 0) {
      return;
    }

    const tabObj = tabParam ? TAB_LIST[tabParam] : null;

    if (tabObj) {
      const url = new URL(window.location.href); // Was window.location
      url.searchParams.set('tab', tabObj.key);
      window.history.replaceState(null, '', url.toString());
      setTab(tabObj.key);
    } else {
      setTabNew(Object.keys(TAB_LIST)[0]);
    }
  }, [TAB_LIST, tabParam]);

  const setTab = (tabKey: string): void => {
    setTabNew(tabKey);
    const url = new URL(window.location.href);
    url.searchParams.set('tab', tabKey);
    window.history.pushState(null, '', url.toString());
  };

  React.useEffect(() => {
    const loadEvent = async (): Promise<any> => {
      try {
        const readEvent = await EventModel.fetch(eventId);

        setEvent(readEvent as EventModel);
        addEvent(readEvent as EventModel);
        setHasLoaded(true);
      } catch (error) {
        switchPath('/');
      }
    };

    loadEvent();
  }, [eventId]);

  const navigationLabel = event?.isUpcomingEvent ? 'Roster' : 'Results';

  const backToEvents = (
    <BackLink href={`/fantasy/leagues/${league.id}/events`}>
      <Icon
        type={ICON_TYPES.CIRCLE_RIGHT}
        size={20}
        color={ICON_COLORS.EMPHASIS}
      />
      <span>{'Back to Events'}</span>
    </BackLink>
  );

  const content = React.useMemo(() => {
    if (!hasLoaded) {
      return null;
    }

    if (tab === 'picks') {
      return <YourPicks eventId={eventId} league={league} />;
    } else if (tab === 'results') {
      return <Results eventId={eventId} league={league} />;
    } else if (tab === 'darkHorse') {
      return <DarkHorse eventId={eventId} league={league} />;
    }

    return null;
  }, [tab, hasLoaded]);

  return (
    <Container>
      {backToEvents}
      <EventCard>
        <NameHeader className="text-2xl font-bold">{event?.name}</NameHeader>
        <PresentedByHeader>{event?.presentedBy}</PresentedByHeader>
        <Divider />
        <DateHeader>{`${event?.formattedStartDate ?? ''} - ${event?.formattedEndDate ?? ''}`}</DateHeader>
        <LocationHeader>{event?.location}</LocationHeader>
        {event?.updatedAt && (
          <UpdatedHeader>{`Last Updated ${event?.updatedAtString}`}</UpdatedHeader>
        )}
      </EventCard>
      <div>
        <TabPanel
          maxWidth="100%"
          tabs={TAB_LIST}
          tab={tab}
          setTab={setTabNew}
          tabContent={content}
          navigationLabel={navigationLabel}
          navigationWidth={182}
        />
      </div>
    </Container>
  );
};

export default Event;
