import Model from './Model';
import CompetitorModel from './CompetitorModel';

class EventCompetitorModel extends Model {
  public division: string;
  public place?: number;
  public score?: number | null;
  public competitorId: string;
  public eventId: string;
  public points?: number;

  static _models = {};

  get competitor(): CompetitorModel | null {
    const model = CompetitorModel.find(this.competitorId);
    return model ? (model as CompetitorModel) : null;
  }

  get ordinalPlace(): string {
    if (!this.place) {
      return '-';
    }

    const lastDigit = this.place % 10;
    const lastTwoDigits = this.place % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return this.place + 'th';
    }

    switch (lastDigit) {
      case 1:
        return this.place + 'st';
      case 2:
        return this.place + 'nd';
      case 3:
        return this.place + 'rd';
      default:
        return this.place + 'th';
    }
  }

  get scoreString(): string {
    if (this.score === null || this.score === undefined) {
      return '-';
    }

    if (this.score < 0) {
      return String(this.score);
    } else if (this.score === 0) {
      return 'E';
    } else {
      return `+${this.score}`;
    }
  }
}

Model.registerModel('EventCompetitors', EventCompetitorModel);

export default EventCompetitorModel;
