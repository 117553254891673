import React from 'react';
import type LeagueModel from '../../../../api/LeagueModel';
import { useLeagueStore } from '../../../../stores';
import TabPanel from '../../../../components/TabPanel';
import Form from '../../../../components/Form';
import FormInput from '../../../../components/FormInput';
import FormSubmit from '../../../../components/FormSubmit';
import EventSettings from './LeagueSettingsTabs/EventSettings';

interface SettingsProps {
  league: LeagueModel;
}

const TABS = {
  general: {
    key: 'general',
    title: 'General',
    header: 'General Settings',
    description: 'Update the settings for your league to match your needs.',
  },
  events: {
    key: 'events',
    title: 'Events',
    header: 'Event Settings',
    description: 'Configure the events for your league.',
  },
};

interface SubSettingsProps {
  league: LeagueModel;
}

const GeneralSettings: React.FC<SubSettingsProps> = ({ league }) => {
  const [leagueName, setLeagueName] = React.useState(league.name);
  const [rosterSize, setRosterSize] = React.useState(String(league.rosterSize));
  const [pickCount, setPickCount] = React.useState(String(league.pickCount));
  const [isLoading, setIsLoading] = React.useState(false);
  const addLeague = useLeagueStore((state) => state.addLeague);

  const inputDisabled = React.useMemo(() => {
    return !league.isOwner || isLoading;
  }, [league.isOwner, isLoading]);

  const isDirty = React.useMemo(() => {
    return (
      leagueName !== league.name ||
      Number(rosterSize) !== league.rosterSize ||
      Number(pickCount) !== league.pickCount
    );
  }, [
    league.name,
    league.rosterSize,
    league.pickCount,
    rosterSize,
    pickCount,
    leagueName,
  ]);

  const canSubmit = React.useMemo(() => {
    return isDirty && league.isOwner && !isLoading;
  }, [isDirty, league.isOwner, isLoading]);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const updatedLeague = await league.update({
        leagueName,
        rosterSize,
        pickCount,
      });
      addLeague(updatedLeague);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput
        inputKey="league-name"
        label="League Name"
        onChange={(e) => {
          if (e.target.value.length > 256) {
            return;
          }
          setLeagueName(e.target.value);
        }}
        required
        value={leagueName}
        disabled={inputDisabled}
      />
      <FormInput
        inputKey="roster-size"
        label="Roster Size"
        onChange={(e) => {
          const newValue = Number(e.target.value);
          if (isNaN(newValue) && e.target.value !== '') {
            return;
          }
          setRosterSize(e.target.value);
        }}
        required
        value={rosterSize}
        disabled={inputDisabled}
      />
      <FormInput
        inputKey="pick-count"
        label="Picks per Event"
        onChange={(e) => {
          const newValue = Number(e.target.value);
          if (isNaN(newValue) && e.target.value !== '') {
            return;
          }
          setPickCount(e.target.value);
        }}
        required
        value={pickCount}
        disabled={inputDisabled}
      />
      <FormInput
        inputKey="invite-link"
        label="Invite Link"
        value={`https://app.kendisc.com/fantasy/leagues/join/${league.id}?pwd=${league.password}`}
        disabled
      />
      <FormSubmit hidden={!league.isOwner} disabled={!canSubmit} />
    </Form>
  );
};

const LeagueSettings: React.FC<SettingsProps> = ({ league }) => {
  const [tab, setTab] = React.useState('general');

  const tabContent = React.useMemo(() => {
    if (tab === 'general') {
      return <GeneralSettings league={league} />;
    } else if (tab === 'events') {
      return <EventSettings league={league} />;
    }
    return null;
  }, [tab]);

  return (
    <TabPanel tabs={TABS} tab={tab} setTab={setTab} tabContent={tabContent} />
  );
};

export default LeagueSettings;
