import React from 'react';
import WeeklyPick, {
  type WeeklyPickPick,
  type WeeklyPickCompetitor,
} from '../../../../api/WeeklyPick';
import styled from 'styled-components';
import Checkbox from '../../../../components/Checkbox';
import GenericTable from '../league/GenericTable';
import switchPath from '../../../../helpers/switchPath';
import TabPanel from '../../../../components/TabPanel';

interface EventPicksProps {
  eventId: string;
}

const CheckCell = styled.div`
  width: 100px;
  @media (max-width: 768px) {
    width: 40px;
  }
`;

const TABS = {
  mpo: {
    key: 'mpo',
    title: 'MPO',
    header: 'MPO Picks',
    description: 'Pick three MPO players for the event.',
  },
  fpo: {
    key: 'fpo',
    title: 'FPO',
    header: 'FPO Picks',
    description: 'Pick three FPO players for the event.',
  },
};

const EventPicks: React.FC<EventPicksProps> = ({ eventId }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [sortBy] = React.useState<string>('rating');
  const [competitors, setCompetitors] = React.useState<WeeklyPickCompetitor[]>(
    [],
  );
  const [allPicks, setAllPicks] = React.useState<WeeklyPickPick[]>([]);
  const params = new URLSearchParams(window.location.search);
  const tabParam = params.get('tab');
  const [tab, setTab] = React.useState<string>(tabParam ?? 'mpo');

  React.useEffect(() => {
    const loadData = async (): Promise<any> => {
      try {
        const data = await WeeklyPick.fetchInitialData(eventId);
        setCompetitors(data.competitors ?? []);
        setAllPicks(data.picks ?? []);
      } catch (error) {
        switchPath('/');
      }
    };

    loadData();
  }, [eventId]);

  const picks = React.useMemo(() => {
    if (tab === 'mpo') {
      return allPicks.filter((pick) => pick.division === 'MPO');
    } else {
      return allPicks.filter((pick) => pick.division === 'FPO');
    }
  }, [tab, allPicks]);

  const sortedCompetitors = React.useMemo(() => {
    const filteredCompetitors = competitors.filter(
      (competitor) => competitor.division === tab.toUpperCase(),
    );

    filteredCompetitors.sort((a, b) => {
      return b.rating - a.rating;
    });

    return filteredCompetitors;
  }, [competitors, tab, sortBy]);

  const rows = React.useMemo(() => {
    return sortedCompetitors.map((competitor) => {
      const isSelected = picks.some(
        (pick) => pick.competitorId === competitor.competitorId,
      );
      const checkCell = (
        <CheckCell>
          <Checkbox
            id={`weekly-pick-${competitor.competitorId}`}
            checked={isSelected}
          />
        </CheckCell>
      );
      const cells = [checkCell, competitor.name, String(competitor.rating)];

      const disableClick = isSaving || (!isSelected && picks.length >= 3);

      const onClick = disableClick
        ? undefined
        : async (): Promise<any> => {
            try {
              setIsSaving(true);
              if (isSelected) {
                const oldPick = await WeeklyPick.deletePick(
                  eventId,
                  competitor.competitorId,
                );
                setAllPicks((picks) => {
                  return picks.filter((pick) => pick.id !== oldPick.id);
                });
              } else {
                const newPick = await WeeklyPick.createPick(
                  eventId,
                  competitor.competitorId,
                );
                setAllPicks((picks) => {
                  return [...picks, newPick];
                });
              }
            } finally {
              setIsSaving(false);
            }
          };

      return {
        cells,
        onClick,
        key: competitor.competitorId,
      };
    });
  }, [sortedCompetitors, picks, isSaving, eventId]);

  const headers = React.useMemo(() => {
    return [`${picks.length} / 3 Picks`, 'Name', 'Rating'];
  }, [picks.length]);

  return (
    <TabPanel
      tabs={TABS}
      tab={tab}
      setTab={setTab}
      navigationLabel="Par 3 Pick 'em"
      navigationWidth={182}
      maxWidth="100%"
      tabContent={<GenericTable headers={headers} rows={rows} />}
    />
  );
};

export default EventPicks;
