import React from 'react';
import type LeagueModel from '../../../../../api/LeagueModel';
import type { EventResult } from '../../../../../api/LeagueModel';
import styled from 'styled-components';
import Styles from '../../../../../helpers/styles';

const Container = styled.div`
  width: 100%;
`;
const TeamTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  margin-top: 10px;
`;
const TeamHeader = styled.th`
  text-align: left;
  color: ${Styles.colors.white};
`;
const TeamCell = styled.td`
  color: ${Styles.colors.white};
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid ${Styles.colors.white};
  padding-top: 10px;
`;
const PlayerCell = styled.td`
  color: ${Styles.colors.white};
`;

interface Props {
  eventId: string;
  league: LeagueModel;
}

const Results: React.FC<Props> = ({ league, eventId }) => {
  const [results, setResults] = React.useState<EventResult | null>(null);
  React.useEffect(() => {
    const load = async (): Promise<any> => {
      const loaded = await league.getResults(eventId);

      setResults(loaded);
    };

    load();
  }, [eventId]);

  const teams = React.useMemo(() => {
    if (!results?.results) {
      return null;
    }

    const sortedResults = results.results;
    sortedResults.sort((a, b) => b.points - a.points);

    return results.results.map((result, index) => {
      const sortedCompetitors = Array.from(result.competitors);
      sortedCompetitors.sort((a, b) => {
        if (a.darkHorse && !b.darkHorse) {
          return 1;
        } else if (b.darkHorse && !a.darkHorse) {
          return -1;
        }
        return (a.place ?? Number.MAX_VALUE) - (b.place ?? Number.MAX_VALUE);
      });
      const playerRows = sortedCompetitors.map((competitor, innerIndex) => {
        return (
          <tr key={innerIndex}>
            <PlayerCell>
              {competitor.name}
              {competitor.darkHorse ? '*' : null}
            </PlayerCell>
            <PlayerCell>{competitor.place ?? '-'}</PlayerCell>
            <PlayerCell>
              {(competitor.points ?? 0).toFixed(2).replace(/[.,]00$/, '')}
            </PlayerCell>
          </tr>
        );
      });
      return (
        <React.Fragment key={index}>
          <tr>
            <TeamCell>{result.teamName}</TeamCell>
            <TeamCell>{index + 1}</TeamCell>
            <TeamCell>
              {(result.points ?? 0).toFixed(2).replace(/[.,]00$/, '')}
            </TeamCell>
          </tr>
          {playerRows}
        </React.Fragment>
      );
    });
  }, [results?.results]);

  if (!results) {
    return null;
  }

  return (
    <Container>
      <TeamTable>
        <thead>
          <tr>
            <TeamHeader>Name</TeamHeader>
            <TeamHeader>Place</TeamHeader>
            <TeamHeader>Points</TeamHeader>
          </tr>
        </thead>
        <tbody>{teams}</tbody>
      </TeamTable>
    </Container>
  );
};

export default Results;
