import Request from './Request';

type GuessDirection = 'equal' | 'higher' | 'lower';

export interface DiscleCompetitor {
  id: string;
  name: string;
}

export interface DiscleGuess {
  name: string;
  id: string;
  correct: boolean;
  rating: number;
  ratingDir: GuessDirection;
  ratingClose: boolean;
  pdgaNumber: string;
  pdgaNumberDir: GuessDirection;
  pdgaNumberClose: boolean;
  manufacturerName: string;
  manufacturerNameDir: 'equal' | 'different';
  manufacturerNameClose: boolean;
  division: 'MPO' | 'FPO' | 'Unknown';
  divisionDir: 'equal' | 'different';
}

const Discle = {
  getGuessableCompetitors: async (): Promise<DiscleCompetitor[]> => {
    const data = await Request('/discle/competitors');

    return data;
  },

  guessCompetitor: async (competitorId: string): Promise<DiscleGuess> => {
    const result = await Request(`/discle/guess`, {
      method: 'POST',
      body: { competitorId },
    });

    return result;
  },

  getAnswer: async (): Promise<DiscleGuess> => {
    const result = await Request('/discle/answer');

    return result;
  },
};

export default Discle;
