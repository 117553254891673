import React from 'react';
import styled from 'styled-components';
import Styles from '../../../helpers/styles';
import { useEventStore } from '../../../stores';
import EventModel from '../../../api/EventModel';
import GenericTable from './league/GenericTable';
import switchPath from '../../../helpers/switchPath';

const Container = styled.div`
  padding: 0px 40px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    padding: 0px 10px;
  }
`;

const SubHeaderText = styled.h2`
  color: ${Styles.colors.font};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;

const Name = styled.span`
  font-weight: 700;
`;

const PresentedBy = styled.span`
  font-size: 13px;
`;

const WeeklyPicks: React.FC = () => {
  const events = useEventStore((state) => state.events);
  const addEvents = useEventStore((state) => state.addEvents);

  React.useEffect(() => {
    const loadEvents = async (): Promise<any> => {
      const readEvents = await EventModel.fetchAll();
      addEvents(readEvents as Record<string, EventModel>);
    };

    loadEvents();
  }, []);

  const pastEvents = React.useMemo(() => {
    const pastEvents = Object.values(events).filter(
      (event) => event.isPastEvent,
    );
    pastEvents.sort((a, b) => {
      const aEndDate = new Date(a.endDate);
      const bEndDate = new Date(b.endDate);
      return aEndDate < bEndDate ? -1 : 1;
    });
    return pastEvents;
  }, [events]);

  const futureEvents = React.useMemo(() => {
    const futureEvents = Object.values(events).filter(
      (event) => !event.isPastEvent,
    );
    pastEvents.sort((a, b) => {
      const aEndDate = new Date(a.endDate);
      const bEndDate = new Date(b.endDate);
      return aEndDate < bEndDate ? -1 : 1;
    });
    return futureEvents;
  }, [events]);

  const futureRows = React.useMemo(() => {
    return futureEvents.map((e) => {
      const onClick = (): void => {
        switchPath(`/par-three-picks/events/${e.id}`);
      };
      const nameCell = (
        <NameContainer>
          <Name>{e.name}</Name>
          <PresentedBy>{e.presentedBy}</PresentedBy>
        </NameContainer>
      );
      const cells = [
        nameCell,
        e.location,
        e.formattedStartDate,
        e.formattedEndDate,
      ];

      return {
        onClick,
        cells,
        key: e.id,
      };
    });
  }, [futureEvents]);

  const pastRows = React.useMemo(() => {
    return pastEvents.map((e) => {
      const onClick = (): void => {
        switchPath(`/par-three-picks/events/${e.id}`);
      };
      const nameCell = (
        <NameContainer>
          <Name>{e.name}</Name>
          <PresentedBy>{e.presentedBy}</PresentedBy>
        </NameContainer>
      );
      const cells = [
        nameCell,
        e.location,
        e.formattedStartDate,
        e.formattedEndDate,
      ];

      return {
        onClick,
        cells,
        key: e.id,
      };
    });
  }, [pastEvents]);

  const headers = ['Name', 'Location', 'Start', 'End'];

  return (
    <Container>
      <SubHeaderText className="text-2xl font-semibold mb-4">
        {'Current/Upcoming Events'}
      </SubHeaderText>
      <GenericTable
        headers={headers}
        rows={futureRows}
        mobileHiddenIndices={[1, 3]}
      />
      <SubHeaderText className="text-2xl font-semibold my-4">
        {'Past Events'}
      </SubHeaderText>
      <GenericTable
        headers={headers}
        rows={pastRows}
        mobileHiddenIndices={[1, 3]}
      />
    </Container>
  );
};

export default WeeklyPicks;
