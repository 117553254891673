import { create } from 'zustand';
import type EventModel from '../api/EventModel';

interface EventState {
  events: Record<string, EventModel>;
  addEvent: (event: EventModel) => void;
  addEvents: (eventSet: Record<string, EventModel>) => void;
  getEvent: (eventId: string) => EventModel;
}

const useEventStore = create<EventState>((set, get) => ({
  events: {},
  addEvent: (event: EventModel) =>
    set(({ events }) => ({ events: { ...events, [event.id]: event } })),
  addEvents: (eventSet) =>
    set(({ events }) => ({ events: { ...events, ...eventSet } })),
  getEvent: (eventId: string) => get().events[eventId],
}));

export default useEventStore;
