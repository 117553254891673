import Model from './Model';

class RosterCompetitorModel extends Model {
  static _models = {};

  public competitorId: string;
  public leagueMemberId: string;
  public active: boolean;
}

export default RosterCompetitorModel;
