import React from 'react';
import type { ReactNode } from 'react';

import type LeagueModel from '../../../../api/LeagueModel';
import { useLeagueMemberStore } from '../../../../stores';
import Roster from './Roster';
import type RosterLogModel from '../../../../api/RosterLogModel';
import MobileSwitch from '../../../../components/MobileSwitch';

interface RosterProps {
  league: LeagueModel;
}

const Log: React.FC<{ log: RosterLogModel }> = ({ log }) => {
  let logMessage: ReactNode = null;
  switch (log.eventType) {
    case 'free_agent':
      logMessage = (
        <p>
          <span className="font-semibold">{log.toTeamName}</span>
          <span>{' picked up '}</span>
          <span className="font-semibold">{log.competitorName}</span>
          <span>{' as a free agent.'}</span>
        </p>
      );
      break;
    case 'drop':
      logMessage = (
        <p>
          <span className="font-semibold">{log.fromTeamName}</span>
          <span>{' dropped '}</span>
          <span className="font-semibold">{log.competitorName}</span>
          <span>{'.'}</span>
        </p>
      );
      break;
    case 'draft':
      logMessage = (
        <p>
          <span className="font-semibold">{log.toTeamName}</span>
          <span>{' drafted '}</span>
          <span className="font-semibold">{log.competitorName}</span>
          <span>{'.'}</span>
        </p>
      );
      break;
    case 'trade':
      logMessage = (
        <p>
          <span className="font-semibold">{log.toTeamName}</span>
          <span>{' received '}</span>
          <span className="font-semibold">{log.competitorName}</span>
          <span>{' from '}</span>
          <span className="font-semibold">{log.fromTeamName}</span>
          <span>{'.'}</span>
        </p>
      );
      break;
  }

  return (
    <div className="flex flex-col text-gray-50 border border-gray-400 p-3 rounded-md bg-sky-900">
      <div className="flex flex-row justify-between items-center mb-1">
        <span className="font-semibold text-lg">{log.competitorName}</span>
        <span className="text-gray-400">{log.formattedLogDate}</span>
      </div>
      <div>{logMessage}</div>
    </div>
  );
};

const Rosters: React.FC<RosterProps> = ({ league }) => {
  const leagueMembers = useLeagueMemberStore((state) =>
    state.getLeagueMembersByLeague(league.id),
  );

  const [logs, setLogs] = React.useState<RosterLogModel[]>([]);
  React.useEffect(() => {
    const fetchLogs = async (): Promise<any> => {
      const fetchedLogs = await league.getRosterLogs();
      setLogs(Object.values(fetchedLogs));
    };

    fetchLogs();
  }, []);

  const rosterComponents = leagueMembers.map((leagueMember) => (
    <Roster key={leagueMember.id} league={league} leagueMember={leagueMember} />
  ));

  const rosterList = (
    <div className="flex flex-col items-center w-full">
      <h2 className="text-gray-50 font-semibold text-lg mb-3">Rosters</h2>
      <div className="flex flex-col gap-y-2 justify-start w-full">
        {rosterComponents}
      </div>
    </div>
  );

  return (
    <MobileSwitch
      className="w-full"
      desktop={
        <div className="grid grid-cols-2 gap-x-4 w-full">
          {rosterList}
          <div className="flex flex-col items-center">
            <h2 className="text-gray-50 font-semibold text-lg">
              Roster Activity
            </h2>
            {logs.length === 0 ? null : (
              <div className="flex flex-col gap-y-4 w-full mt-3 h-full">
                {logs.map((log) => (
                  <div key={log.id} className="w-full">
                    <Log log={log} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      }
      mobile={rosterList}
    />
  );
};

export default Rosters;
