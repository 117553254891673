import React from 'react';

import type RosterCompetitorModel from '../api/RosterCompetitorModel';

export default React.createContext<
  [
    Record<string, RosterCompetitorModel>,
    (leagues: Record<string, RosterCompetitorModel>) => void,
  ]
>([{}, () => {}]);
