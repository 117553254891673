import React from 'react';
import classNames from '../helpers/classNames';

const BUTTON_COLORS = {
  EMPHASIS: 'color-emphasis',
  NEGATIVE: 'color-negative',
};

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  label: React.ReactNode;
  color?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  className,
  label,
  color = BUTTON_COLORS.EMPHASIS,
}) => {
  const buttonClasses = classNames('component-button', className, color, {
    disabled,
  });

  return (
    <button
      className={buttonClasses}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      {label}
    </button>
  );
};

export default Button;
