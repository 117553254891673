import React from 'react';
import _ from 'lodash';
import EventCompetitorModel from '../../../api/EventCompetitorModel';
import SortableTableHeader from '../../../components/SortableTableHeader';
import Logo from '../../../components/logo';
import Icon, { ICON_TYPES } from '../../../components/Icon';
import classNames from '../../../helpers/classNames';
import Pick from './Pick';
import type EventModel from '../../../api/EventModel';

const NUMBER_SORTS = ['rating', 'pdgaNumber', 'id', 'place', 'score', 'points'];
const COMPETITOR_SORTS = ['rating', 'pdgaNumber', 'firstName', 'lastName'];

interface Props {
  canMakePick?: boolean;
  division: string;
  event: EventModel;
}

const PicksTable: React.FC<Props> = ({ canMakePick, division, event }) => {
  const [allEventCompetitors, setAllEventCompetitors] = React.useState<
    EventCompetitorModel[] | null
  >(null);
  const [selectedCompetitors, setSelectedCompetitors] = React.useState<
    string[]
  >([]);
  const [sortBy, setSortBy] = React.useState(canMakePick ? 'rating' : 'place');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(
    canMakePick ? 'desc' : 'asc',
  );
  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    const loadCompetitors = async (): Promise<any> => {
      const competitors = await event.readEventCompetitors(division);
      const picks = await event.getPicks(division);
      setAllEventCompetitors(competitors);
      setSelectedCompetitors(picks.map((pick) => pick.eventCompetitorId));
    };
    setAllEventCompetitors(null);
    setSelectedCompetitors([]);
    loadCompetitors();
  }, [division, event.id]);

  const isLoading = React.useMemo(
    () => !allEventCompetitors,
    [allEventCompetitors],
  );

  const handleCheck = async (id: string): Promise<any> => {
    const selectedIndex = selectedCompetitors.indexOf(id);
    const isSelected = selectedIndex !== -1;
    setIsSaving(true);
    if (isSelected) {
      const selectedCopy = Array.from(selectedCompetitors);
      selectedCopy.splice(selectedIndex, 1);
      await event.deletePick(id, division);
      setSelectedCompetitors(selectedCopy);
    } else {
      if (selectedCompetitors.length < 3) {
        await event.makePick(division, id);
        setSelectedCompetitors([...selectedCompetitors, id]);
      }
    }
    setIsSaving(false);
  };

  const sortedAndFilteredEventCompetitors = React.useMemo(() => {
    if (!allEventCompetitors?.length) {
      return [];
    }

    const newComps = Array.from(allEventCompetitors);

    const useNumbers = NUMBER_SORTS.includes(sortBy);
    const useCompetitor = COMPETITOR_SORTS.includes(sortBy);

    if (useNumbers) {
      newComps.sort((a, b) => {
        const aValue = useCompetitor
          ? a.competitor
            ? Number(a.competitor[sortBy])
            : 0
          : Number(a[sortBy]);
        const bValue = useCompetitor
          ? b.competitor
            ? Number(b.competitor[sortBy])
            : 0
          : Number(b[sortBy]);

        if (aValue == null) {
          return 1;
        }
        if (bValue == null) {
          return -1;
        }
        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    } else if (sortBy === 'picked') {
      newComps.sort((a, b) => {
        if (selectedCompetitors.includes(a.id)) {
          return sortDirection === 'asc' ? 1 : -1;
        } else if (selectedCompetitors.includes(b.id)) {
          return sortDirection === 'asc' ? -1 : 1;
        } else {
          return 0;
        }
      });
    } else if (sortBy === 'firstName') {
      newComps.sort((a, b) => {
        const aF = a.competitor?.name.split(' ')[0] ?? '';
        const bF = b.competitor?.name.split(' ')[0] ?? '';
        if (sortDirection === 'asc') {
          return aF.localeCompare(bF);
        } else {
          return bF.localeCompare(aF);
        }
      });
    } else if (sortBy === 'lastName') {
      newComps.sort((a, b) => {
        const aF =
          a.competitor?.name.substring(a.competitor.name.indexOf(' ')) ?? '';
        const bF =
          b.competitor?.name.substring(b.competitor.name.indexOf(' ')) ?? '';
        if (sortDirection === 'asc') {
          return aF.localeCompare(bF);
        } else {
          return bF.localeCompare(aF);
        }
      });
    } else {
      newComps.sort((a, b) => {
        const aVal = useCompetitor
          ? a.competitor
            ? a.competitor[sortBy]
            : null
          : a[sortBy];
        const bVal = useCompetitor
          ? b.competitor
            ? b.competitor[sortBy]
            : null
          : b[sortBy];
        if (typeof aVal !== 'string' || typeof bVal !== 'string') {
          return 0;
        }
        if (sortDirection === 'asc') {
          return aVal.localeCompare(bVal);
        } else {
          return bVal.localeCompare(aVal);
        }
      });
    }

    return newComps;
  }, [allEventCompetitors, sortBy, sortDirection, selectedCompetitors]);

  const competitorRows = React.useMemo(() => {
    return sortedAndFilteredEventCompetitors.map((eventCompetitor) => {
      const splitName = eventCompetitor.competitor?.name.split(' ');
      const firstName = splitName ? splitName[0] : '';
      const lastName = eventCompetitor.competitor?.name.substring(
        firstName.length + 1,
      );
      if (canMakePick) {
        const isSelected = Boolean(
          _.find(
            selectedCompetitors,
            (eventCompetitorId) => eventCompetitor.id === eventCompetitorId,
          ),
        );

        const disabled =
          isSaving || (!isSelected && selectedCompetitors.length >= 3);

        const rowClasses = classNames('competitor-row', {
          clickable: !disabled,
          selected: isSelected,
        });
        const rowClick = disabled
          ? undefined
          : (e) => {
              e.stopPropagation();
              handleCheck(eventCompetitor.id);
            };
        const keyPress = disabled
          ? undefined
          : (e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                handleCheck(eventCompetitor.id);
              }
            };

        return (
          <tr
            key={eventCompetitor.id}
            className={rowClasses}
            onClick={rowClick}
          >
            <td>
              <input
                className="checkbox"
                disabled={disabled}
                type="checkbox"
                checked={isSelected}
                onChange={() => {}}
                onKeyPress={keyPress}
              />
            </td>
            <td className="name">{firstName}</td>
            <td className="name">{lastName}</td>
            <td className="rating">{eventCompetitor.competitor?.rating}</td>
          </tr>
        );
      } else {
        return (
          <tr key={eventCompetitor.id} className="competitor-row">
            <td>{eventCompetitor.place ?? '-'}</td>
            <td className="name">{firstName}</td>
            <td className="name">{lastName}</td>
            <td className="score">
              {eventCompetitor.place ? eventCompetitor.scoreString : '-'}
            </td>
            <td className="score">
              {eventCompetitor.place ? eventCompetitor.points : '-'}
            </td>
          </tr>
        );
      }
    });
  }, [sortedAndFilteredEventCompetitors, selectedCompetitors, isSaving]);

  if (isLoading) {
    return (
      <div className="picks-table">
        <Logo size={30} color="emphasis" spinning />
      </div>
    );
  }

  const selectedCompetitorRows = [0, 1, 2].map((index) => {
    const eventCompetitorId =
      selectedCompetitors.length > index ? selectedCompetitors[index] : null;
    return (
      <Pick
        eventCompetitor={
          EventCompetitorModel.find(eventCompetitorId) as EventCompetitorModel
        }
        key={`competitor-${index}`}
        onRemove={handleCheck}
        canMakePick={canMakePick}
        disabled={isSaving}
      />
    );
  });

  const performSort = (sortKey: string): void => {
    if (sortKey === sortBy) {
      const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
      setSortDirection(newSortDirection);
    } else {
      setSortBy(sortKey);
      setSortDirection('desc');
    }
  };

  const picksHeader = canMakePick ? (
    <h2 className="picks-header">{`Available Competitors (${allEventCompetitors?.length ?? 0})`}</h2>
  ) : (
    <h2 className="picks-header">{`Results (${allEventCompetitors?.length ?? 0})`}</h2>
  );

  const headerRow = canMakePick ? (
    <tr className="header-row">
      <SortableTableHeader
        selected={sortBy === 'picked'}
        sortDirection={sortDirection}
        onSort={() => performSort('picked')}
      />
      <SortableTableHeader
        label={'First'}
        selected={sortBy === 'firstName'}
        sortDirection={sortDirection}
        onSort={() => performSort('firstName')}
      />
      <SortableTableHeader
        label={'Last'}
        selected={sortBy === 'lastName'}
        sortDirection={sortDirection}
        onSort={() => performSort('lastName')}
      />
      <SortableTableHeader
        label={'Rating'}
        selected={sortBy === 'rating'}
        sortDirection={sortDirection}
        onSort={() => performSort('rating')}
      />
    </tr>
  ) : (
    <tr className="header-row">
      <SortableTableHeader
        contentClassName="place-header"
        label={'Place'}
        selected={sortBy === 'place'}
        sortDirection={sortDirection}
        onSort={() => performSort('place')}
      />
      <SortableTableHeader
        label={'First'}
        selected={sortBy === 'firstName'}
        sortDirection={sortDirection}
        onSort={() => performSort('firstName')}
      />
      <SortableTableHeader
        label={'Last'}
        selected={sortBy === 'lastName'}
        sortDirection={sortDirection}
        onSort={() => performSort('lastName')}
      />
      <SortableTableHeader
        contentClassName="score-header"
        label={'Score'}
        selected={sortBy === 'score'}
        sortDirection={sortDirection}
        onSort={() => performSort('score')}
      />
      <SortableTableHeader
        label={'Points'}
        selected={sortBy === 'points'}
        sortDirection={sortDirection}
        onSort={() => performSort('points')}
      />
    </tr>
  );

  const saveRow = canMakePick ? (
    <div className="save-row">
      <Icon
        className={isSaving ? 'saving' : ''}
        type={ICON_TYPES.CIRCLE_CHECK}
        spinning={isSaving}
        size={15}
      />
      {isSaving ? 'Saving picks...' : 'Your picks were auto-saved'}
    </div>
  ) : null;

  const picksTableClasses = classNames('picks-table', {
    'can-make-pick': canMakePick,
  });

  return (
    <div className={picksTableClasses}>
      <h2 className="picks-header">Your Picks</h2>
      <div className="selected-competitors">
        {saveRow}
        <div className="picks-row">{selectedCompetitorRows}</div>
      </div>
      {picksHeader}
      <div className="competitors">
        <table className="competitors-table">
          <thead>{headerRow}</thead>
          <tbody>{competitorRows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default PicksTable;
