import React from 'react';
import type LeagueModel from '../../../../api/LeagueModel';
import Icon, { ICON_TYPES } from '../../../../components/Icon';
import type LeagueMemberModel from '../../../../api/LeagueMemberModel';
import styled from 'styled-components';
import Styles from '../../../../helpers/styles';
import {
  useCompetitorStore,
  useLeagueMemberStore,
  useRosterCompetitorStore,
} from '../../../../stores';
import type CompetitorModel from '../../../../api/CompetitorModel';

const SearchRow = styled.div`
  color: black;
  padding: 2px;
  &:hover {
    background-color: ${Styles.colors.emphasis};
  }
  cursor: pointer;
`;

const SearchResult = styled.div`
  display: block;
  position: absolute;
  box-sizing: border-box;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 4px;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${Styles.colors.white};
  color: ${Styles.colors.font};
  outline: none;
  font-size: 15px;
`;

const SearchInputContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const RosterContainer = styled.div`
  background-color: var(--color-surface-two);
  padding: 10px;
  border-radius: 4px;
  min-width: 250px;
`;

const RosterName = styled.h3`
  color: ${Styles.colors.font};
  margin: 0px;
  margin-bottom: 5px;
`;

const RosterNameInput = styled.input`
  margin: 0px;
  margin-bottom: 5px;
`;

const Player = styled.div`
  color: ${Styles.colors.font};
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface RosterProps {
  league: LeagueModel;
  leagueMember: LeagueMemberModel;
}

const Roster: React.FC<RosterProps> = ({ league, leagueMember }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [teamName, setTeamName] = React.useState(leagueMember.teamName);
  const [playerSearch, setPlayerSearch] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<CompetitorModel[]>(
    [],
  );

  const rosterCompetitors = useRosterCompetitorStore((state) =>
    state.getRosterCompetitorsByLeagueMember(leagueMember.id),
  );
  const [getCompetitor, addCompetitors] = useCompetitorStore((state) => [
    state.getCompetitor,
    state.addCompetitors,
  ]);
  const [addRosterCompetitor, removeRosterCompetitor] =
    useRosterCompetitorStore((state) => [
      state.addRosterCompetitor,
      state.removeRosterCompetitor,
    ]);

  const addLeagueMember = useLeagueMemberStore(
    (state) => state.addLeagueMember,
  );

  const isLeagueOwner = React.useMemo(() => {
    return league.isOwner;
  }, [league]);

  const onClick = isEditing
    ? async () => {
        if (teamName !== leagueMember.teamName) {
          const refreshedLeagueMember = await league.updateTeamName(teamName);
          addLeagueMember(league.id, refreshedLeagueMember);
        }
        setIsEditing(false);
      }
    : () => {
        setIsEditing(true);
        setPlayerSearch('');
      };

  React.useEffect(() => {
    const search = async (): Promise<any> => {
      if (!playerSearch) {
        setSearchResults([]);
        return;
      }

      const foundPlayers = await league.getFreeAgents({ name: playerSearch });
      addCompetitors(foundPlayers);
      setSearchResults(Object.values(foundPlayers));
    };

    search();
  }, [playerSearch]);

  const searchResultsDisplay = React.useMemo(() => {
    if (!isEditing) {
      return null;
    }

    return searchResults.map((competitor) => {
      const onClick = async (): Promise<any> => {
        const newComp = await league.addToRoster({
          competitorId: competitor.id,
          leagueMemberId: leagueMember.id,
        });
        if (newComp) {
          addRosterCompetitor(newComp);
          setPlayerSearch('');
        }
      };

      return (
        <SearchRow key={competitor.id} onClick={onClick}>
          {competitor.name}
        </SearchRow>
      );
    });
  }, [searchResults, isEditing, league]);

  const editIcon =
    league.isOwner || leagueMember.isOwner ? (
      <Icon
        onClick={onClick}
        size={20}
        type={isEditing ? ICON_TYPES.CIRCLE_CHECK : ICON_TYPES.PENCIL}
      />
    ) : null;

  const nameRow =
    isEditing && leagueMember.isOwner ? (
      <NameRow>
        <RosterNameInput
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
        {editIcon}
      </NameRow>
    ) : (
      <NameRow>
        <RosterName>{`${leagueMember.teamName}`}</RosterName>
        {editIcon}
      </NameRow>
    );

  const rows = rosterCompetitors.map((rosterCompetitor) => {
    const competitor = getCompetitor(rosterCompetitor.competitorId);
    const remove = async (): Promise<any> => {
      await league.removeFromRoster(rosterCompetitor.id);
      removeRosterCompetitor(rosterCompetitor);
    };
    return (
      <Player key={rosterCompetitor.id}>
        {isEditing && isLeagueOwner && (
          <Icon onClick={remove} size={20} type={ICON_TYPES.CIRCLE_REMOVE} />
        )}
        <span>{competitor.name}</span>
      </Player>
    );
  });
  let addRow: React.ReactElement | null = null;

  if (isEditing && isLeagueOwner && rows.length < league.rosterSize) {
    addRow = (
      <Player>
        <Icon size={20} type={ICON_TYPES.CIRCLE_PLUS} />
        <SearchInputContainer>
          <SearchInput
            value={playerSearch}
            onChange={(e) => {
              setPlayerSearch(e.target.value);
            }}
          />
          <SearchResult>{searchResultsDisplay}</SearchResult>
        </SearchInputContainer>
      </Player>
    );
  }

  return (
    <RosterContainer key={leagueMember.id}>
      {nameRow}
      <hr />
      {rows}
      {addRow}
    </RosterContainer>
  );
};

export default Roster;
