const Styles = {
  colors: {
    page: '#21202D',
    font: '#f3f2ff',
    green: '#0c9c29',
    emphasis: '#207dff',
    blueBackground: '#263894',
    white: '#ffffff',
    border: '#323e48',
  },
  // mobileBreakpoint: '768px',
  mobileBreakpoint: '810px',
  smallScreenBreakpoint: '880px',
  surfaceBoxShadow: '0 2px 4px 0 rgba(0,0,0,.3), 0 2px 6px 2px rgba(0,0,0,.15)',
};

export default Styles;
