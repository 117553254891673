import React from 'react';

import EventModel from '../../api/EventModel';

import classNames from '../../helpers/classNames';

import Link from '../../components/Link';
import Logo from '../../components/logo';

import EventTable from './event/EventTable';
import EventCard from './event/EventCard';
import Icon, { ICON_COLORS, ICON_TYPES } from '../../components/Icon';

interface Props {
  signedIn?: boolean;
}

const EventPage: React.FC<Props> = ({ signedIn }) => {
  const splitPath = location.pathname.split('/');
  const eventId = splitPath[splitPath.length - 1];
  const [discGolfEvent, setDiscGolfEvent] = React.useState<EventModel | null>(
    null,
  );

  React.useEffect(() => {
    const loadEvent = async (): Promise<any> => {
      if (!discGolfEvent) {
        const eventRead = await EventModel.fetch(eventId);
        setDiscGolfEvent(eventRead as EventModel);
      }
    };

    loadEvent();
  });
  const pageClasses = classNames('page', 'event');

  let table: React.ReactNode;

  if (!discGolfEvent) {
    table = <Logo size={30} color="emphasis" spinning />;
  } else if (signedIn) {
    table = (
      <EventTable
        canMakePick={discGolfEvent.isUpcomingEvent}
        event={discGolfEvent}
      />
    );
  } else if (signedIn) {
    table = <span>{'Verify your email to make picks and view results.'}</span>;
  } else {
    table = (
      <span>
        <Link href="/sign-in">Sign In</Link>
        {' to make picks and view results.'}
      </span>
    );
  }

  const backToEvents = (
    <Link className="back-link" href="/fantasy">
      <Icon
        type={ICON_TYPES.CIRCLE_RIGHT}
        size={20}
        color={ICON_COLORS.EMPHASIS}
      />
      <div>{'Back to Events'}</div>
    </Link>
  );

  return (
    <div className={pageClasses}>
      <div className="content">
        {backToEvents}
        {discGolfEvent && <EventCard discGolfEvent={discGolfEvent} />}
        {table}
      </div>
    </div>
  );
};

export default EventPage;
