import React from 'react';
import Styles from '../helpers/styles';
import styled from 'styled-components';
import type { ReactNode } from 'react';

const DesktopContainer = styled.div`
  @media (max-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  @media (min-width: ${Styles.mobileBreakpoint}) {
    display: none;
  }
`;

const MobileSwitch: React.FC<{
  className?: string;
  mobile: ReactNode;
  desktop: ReactNode;
}> = ({ mobile, desktop, className }) => {
  return (
    <>
      <DesktopContainer className={className}>{desktop}</DesktopContainer>
      <MobileContainer className={className}>{mobile}</MobileContainer>
    </>
  );
};

export default MobileSwitch;
