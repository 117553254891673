import React from 'react';

import LeagueModel from '../../../api/LeagueModel';
import styled from 'styled-components';
import Styles from '../../../helpers/styles';
import Rosters from './league/Rosters';
import Events from './league/Events';
import Event from './league/Event';
import switchPath from '../../../helpers/switchPath';
import {
  useCompetitorStore,
  useLeagueMemberStore,
  useLeagueStore,
  useRosterCompetitorStore,
} from '../../../stores';
import Standings from './league/Standings';
import LeagueSettings from './league/LeagueSettings';
import TabBar from '../../../components/TabBar';
import FreeAgents from '../../leagues/leagueId/free-agents';

const Page = styled.div`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const LeagueContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px;

  @media (max-width: ${Styles.smallScreenBreakpoint}) {
    padding: 0px 5px;
  }
`;

const TABS = {
  events: {
    id: 'events',
    name: 'Events',
  },
  standings: {
    id: 'standings',
    name: 'Standings',
  },
  teams: {
    id: 'teams',
    name: 'Teams',
  },
  settings: {
    id: 'settings',
    name: 'Settings',
  },
};

interface LeagueProps {
  leagueId: string;
  tab: string;
  extraPath: string[];
}

const League: React.FC<LeagueProps> = ({ leagueId, tab, extraPath }) => {
  const league = useLeagueStore((state) => state.getLeague(leagueId));
  const addLeague = useLeagueStore((state) => state.addLeague);
  const addLeagueMembers = useLeagueMemberStore(
    (state) => state.addLeagueMembers,
  );
  const addRosterCompetitors = useRosterCompetitorStore(
    (state) => state.addRosterCompetitors,
  );
  const addCompetitors = useCompetitorStore((state) => state.addCompetitors);

  const content = React.useMemo(() => {
    if (!league) {
      return null;
    }

    if (tab === 'teams') {
      return <Rosters league={league} />;
    } else if (tab === 'events') {
      if (extraPath.length === 0) {
        return <Events league={league} />;
      } else {
        return <Event league={league} eventId={extraPath[0]} />;
      }
    } else if (tab === 'settings') {
      return <LeagueSettings league={league} />;
    } else if (tab === 'standings') {
      return <Standings league={league} />;
    } else if (tab === 'free-agents') {
      return <FreeAgents league={league} />;
    }

    return null;
  }, [tab, extraPath, league]);

  const setTab = (tabKey: string): void => {
    switchPath(`/fantasy/leagues/${leagueId}/${tabKey}`);
  };

  React.useEffect(() => {
    const loadLeague = async (): Promise<any> => {
      try {
        const readLeague =
          league ?? ((await LeagueModel.read(leagueId)) as LeagueModel);
        const [
          loadedLeagueMembers,
          loadedRosterCompetitors,
          loadedCompetitors,
        ] = await Promise.all([
          readLeague.getLeagueMembers(),
          readLeague.getRosterCompetitors(),
          readLeague.getActiveCompetitors(),
        ]);
        addRosterCompetitors(Object.values(loadedRosterCompetitors));
        addLeagueMembers(readLeague.id, loadedLeagueMembers);
        addCompetitors(loadedCompetitors);
        addLeague(readLeague);
      } catch (error) {
        switchPath('/');
      }
    };

    if (leagueId) {
      loadLeague();
    }
  }, [leagueId]);

  if (!league) {
    return <div></div>;
  }

  return (
    <Page>
      <TabBar tabs={TABS} tab={tab} setTab={setTab} />
      <LeagueContent>{content}</LeagueContent>
    </Page>
  );
};

export default League;
