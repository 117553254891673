import Model from './Model';

export type RosterLogEventType = 'free_agent' | 'draft' | 'trade' | 'drop';

class RosterLogModel extends Model {
  static apiPath = '/roster-logs';
  public id: string;
  public eventType: RosterLogEventType;
  public occurredAt: string;
  public competitorName: string;
  public fromTeamName: string | undefined;
  public toTeamName: string | undefined;

  get formattedLogDate(): string {
    if (!this.occurredAt) {
      return '';
    }

    return new Date(this.occurredAt).toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }
}

Model.registerModel('roster_logs', RosterLogModel);

export default RosterLogModel;
