import Model from './Model';
import Request from './Request';

export interface RequestType {
  id: string;
  userId: string;
  request: string;
}

export interface AdminUserModel {
  id: string;
  username: string;
  isAdmin: boolean;
}

export interface AdminCompetitorModel {
  id: string;
  name: string;
  manufacturerId: string;
}

const Admin = {
  getRequests: async (): Promise<RequestType[]> => {
    return Request('/admin/requests');
  },

  getBackfillCompetitors: async (): Promise<AdminCompetitorModel[]> => {
    const data = await Request('/admin/competitors');

    return data;
  },

  updateCompetitorManufacturer: async (
    competitorId: string,
    manufacturerId: string,
  ): Promise<AdminCompetitorModel> => {
    const data = await Request('/admin/competitor-manufacturer', {
      method: 'PUT',
      body: {
        competitorId,
        manufacturerId,
      },
    });

    return data;
  },

  getCompetitors: async () => {
    if (!Admin.competitors.length) {
      const data = await Request('/admin/competitors');
      Admin.competitors = data;
    }

    return Admin.competitors;
  },

  deleteRequest: async (requestId) => {
    return Request('/admin/requests', {
      method: 'DELETE',
      body: {
        requestId,
      },
    });
  },

  createEvent: async ({
    name,
    presentedBy,
    location,
    tier,
    startDate,
    endDate,
    pdgaEventId,
  }) => {
    const result = await Request('/admin/events', {
      method: 'POST',
      body: {
        name,
        presentedBy,
        tier,
        location,
        startDate: new Date(startDate as string).toISOString(),
        endDate: new Date(endDate as string).toISOString(),
        pdgaEventId,
      },
    });

    return Model.parseFetchData(result);
  },

  createManufacturer: async ({ name }) => {
    const result = await Request('/admin/manufacturers', {
      method: 'POST',
      body: { name },
    });

    return Model.parseFetchData(result);
  },

  refreshPlayers: async () => {
    return Request('/admin/refresh-players', { method: 'POST' });
  },

  getUsers: async (userSearch: string): Promise<AdminUserModel[]> => {
    if (!userSearch) {
      return [];
    }

    const data = await Request(`/admin/users?username=${userSearch}`);
    return data.map((dataPoint) => {
      return {
        id: dataPoint.id,
        username: dataPoint.username,
        isAdmin: dataPoint.is_admin,
      };
    });
  },

  getEvents: async () => {
    return Request('/admin/events');
  },

  startEvent: async (eventId: string) => {
    return Request(`/admin/events/${eventId}`, {
      method: 'PUT',
      body: {
        actionCode: 'start',
      },
    });
  },

  finishEvent: async (eventId: string) => {
    return Request(`/admin/events/${eventId}`, {
      method: 'PUT',
      body: {
        actionCode: 'finish',
      },
    });
  },

  refreshEvent: async (eventId: string) => {
    return Request(`/admin/events/${eventId}`, {
      method: 'PUT',
      body: {
        actionCode: 'refresh',
      },
    });
  },

  events: [],
  competitors: [],
};

export default Admin;
