import type CompetitorModel from './CompetitorModel';
import EventCompetitorModel from './EventCompetitorModel';
import Model from './Model';

class PickModel extends Model {
  public eventCompetitorId: string;

  static _models = {};

  get eventCompetitor(): EventCompetitorModel | null {
    return EventCompetitorModel.find(
      this.eventCompetitorId,
    ) as EventCompetitorModel;
  }

  get competitor(): CompetitorModel | null | undefined {
    return this.eventCompetitor?.competitor;
  }

  get division(): string | undefined {
    return this.eventCompetitor?.division;
  }
}

Model.registerModel('Picks', PickModel);

export default PickModel;
