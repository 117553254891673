import Model from './Model';
import singletons from './singletons';

class LeagueMemberModel extends Model {
  public leagueId: string;
  public userId: string;
  public teamName: string;

  get isOwner(): boolean {
    return (
      this.userId ===
      (singletons.cloakedUserId ?? singletons.auth?.currentUser?.uid)
    );
  }
}

export default LeagueMemberModel;
