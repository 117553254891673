import { create } from 'zustand';
import type LeagueModel from '../api/LeagueModel';

interface LeagueState {
  leagues: Record<string, LeagueModel>;
  clear: () => void;
  addLeague: (league: LeagueModel) => void;
  addLeagues: (leagueSet: Record<string, LeagueModel>) => void;
  getLeague: (leagueId: string) => LeagueModel;
  hasLeague: (leagueId: string) => boolean;
}

const useLeagueStore = create<LeagueState>((set, get) => ({
  leagues: {},
  clear: () => set(() => ({ leagues: {} })),
  addLeague: (league: LeagueModel) =>
    set(({ leagues }) => ({ leagues: { ...leagues, [league.id]: league } })),
  addLeagues: (leagueSet) =>
    set(({ leagues }) => ({ leagues: { ...leagues, ...leagueSet } })),
  getLeague: (leagueId: string) => get().leagues[leagueId],
  hasLeague: (leagueId: string) => Boolean(get().leagues[leagueId]),
}));

export default useLeagueStore;
