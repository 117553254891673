import React from 'react';
import LeagueModel from '../../../api/LeagueModel';
import switchPath from '../../../helpers/switchPath';
import GenericTable from './league/GenericTable';
import styled from 'styled-components';
import { useLeagueStore } from '../../../stores';
import Styles from '../../../helpers/styles';

const Container = styled.div`
  padding: 10px 40px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${Styles.mobileBreakpoint}) {
    padding: 10px 5px;
  }
`;

const Content = styled.div`
  margin-top: 10px;
`;

interface Props {
  signedIn?: boolean;
}

const Leagues: React.FC<Props> = ({ signedIn }) => {
  const leagues = useLeagueStore((state) => state.leagues);
  const addLeagues = useLeagueStore((state) => state.addLeagues);

  React.useEffect(() => {
    const getLeagues = async (): Promise<any> => {
      const fetchedLeagues = await LeagueModel.fetchAll();
      addLeagues(fetchedLeagues as Record<string, LeagueModel>);
    };
    if (signedIn) {
      getLeagues();
    }
  }, [signedIn]);

  const rows = React.useMemo(() => {
    if (!leagues) {
      return [];
    }

    return Object.values(leagues).map((league) => {
      const onClick = (): void => {
        switchPath(`/fantasy/leagues/${league.id}`);
      };
      const mappedCells = [league.name, 'MPO', 'Points', 'Draft'];

      return {
        onClick,
        key: league.id,
        cells: mappedCells,
      };
    });
  }, [leagues]);

  const headers = ['League Name', 'Division', 'Scoring', 'Type'];

  return (
    <Container>
      <Content>
        <GenericTable
          headers={headers}
          rows={rows}
          mobileHiddenIndices={[2, 3]}
        />
      </Content>
    </Container>
  );
};

export default Leagues;
