import React from 'react';
import type EventCompetitorModel from '../../../api/EventCompetitorModel';

interface Props {
  eventCompetitor?: EventCompetitorModel | null;
  onRemove: (id: string) => void;
  canMakePick?: boolean;
  disabled?: boolean;
}

const Pick: React.FC<Props> = ({
  eventCompetitor,
  onRemove,
  canMakePick,
  disabled,
}) => {
  const name = eventCompetitor?.competitor?.name ?? 'Unselected';
  const rating = eventCompetitor?.competitor?.rating ?? 'Rating';
  const place = eventCompetitor?.ordinalPlace ?? 'N/A';
  const score = eventCompetitor ? eventCompetitor.points : 'N/A';
  const onClick = eventCompetitor
    ? () => {
        onRemove(eventCompetitor.id);
      }
    : () => {};
  const buttonClass = eventCompetitor ? 'remove-pick' : 'remove-pick disabled';
  const nameClass = eventCompetitor ? 'name selected' : 'name unselected';
  const ratingClass = eventCompetitor ? 'rating selected' : 'rating unselected';
  const pickClass = eventCompetitor ? 'pick selected' : 'pick unselected';
  const placeClass = eventCompetitor ? 'place selected' : 'place unselected';
  const scoreClass = eventCompetitor ? 'score selected' : 'score unselected';
  const bottomRow = canMakePick ? (
    <button
      className={buttonClass}
      disabled={!eventCompetitor || disabled}
      onClick={onClick}
    >
      <span>
        {'Remove'}
        <span className="pick-text">{' Pick'}</span>
      </span>
    </button>
  ) : (
    <div className={scoreClass}>{`${score ?? 0} Points`}</div>
  );

  return (
    <div className={pickClass}>
      <div className={nameClass}>{name}</div>
      {canMakePick && <div className={ratingClass}>{rating}</div>}
      {!canMakePick && <div className={placeClass}>{place}</div>}
      {bottomRow}
    </div>
  );
};

export default Pick;
